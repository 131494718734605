<template>
  <div>
    <form @submit.prevent="confirmConsultation">
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        id="consultationConfirmModal"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content bg-white">
            <div class="modal-header">
              <h5 class="modal-title client-tab-heading">
                Confirm Consultation with {{ client.name }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal" data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" v-if="consultation && !loading">
              <div class="card bg-light">
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col">
                      <label>Service</label>
                      <select-service
                          :disabled="consultation.clinic_package_id > 0"
                          v-model="consultation.clinic_service" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col">
                      <label>Practitioner</label>
                      <div v-if="consultation.clinic_service">
                        <div v-if="consultation.clinic_service.practitioner_services.length === 0">
                          <p class="text-center">No practitioners are offering this service.</p>
                        </div>
                        <div v-else>
                          <select class="form-control" v-model="consultation.service">
                            <option disabled :value="null">Select a practitioner...</option>
                            <option v-for="practitioner_service in consultation.clinic_service.practitioner_services"
                                    :value="practitioner_service">{{practitioner_service.user.name}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col">
                      <label>Date / Time</label>
                      <utc-flat-pickr v-model="consultation.start" :req="true">
                      </utc-flat-pickr>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col">
                      <label>Delivery Method</label>
                      <select
                        id="consultMethod"
                        v-model="consultation.method"
                        class="form-control"
                        required
                      >
                        <option :value="null" selected disabled>
                          Select Delivery Method
                        </option>
                        <option value="standard">In Person</option>
                        <option value="video">Video</option>
                        <option value="phone">Phone</option>
                      </select>
                      <div v-if="consultation.method === 'video' || consultation.method === 'other'" class="mt-3">
                        <label>External Link (optional)</label>
                        <input
                            class="form-control"
                            placeholder="Provide an external link..."
                            v-model="consultation.external_link"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="blockingEvents.length > 0"
                    class="alert alert-danger"
                  >
                    <p>
                      <i class="far fa-info-circle me-1"></i>
                      Your calendar has events which overlap with this slot:
                    </p>
                    <ul class="ms-5">
                      <li v-for="e in blockingEvents">
                        {{ e.title }}
                        <span v-if="e.client"> with {{ e.client.name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="row mb-3" v-if="!consultation.clinic_package_id">
                    <div class="col-6">
                      <label>Price</label>
                      <currency-input
                        v-model="consultation.service_price"
                        :currency="consultation.currency_name"
                        locale="gb"
                        class="form-control"
                        placeholder="Enter a Price (optional)"
                        id="consultPrice"
                        :min="0"
                        :disabled="consultation.order_status === 'paid'"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <toggle-button v-model="consultation.send_emails"
                                     class="me-1"
                                     :sync="true"
                                     :color="'#03006b'" />
                      <label>Send client notifications</label>
                    </div>
                  </div>
                  <div v-if="consultation.send_emails">
                    <hr />
                    <div class="row mb-2">
                      <div class="col">
                        <toggle-button v-model="consultation.send_confirmation_emails"
                                       class="me-1"
                                       :sync="true"
                                       :color="'#03006b'" />
                        <label>
                          Appointment Confirmation Email
                          <i
                              class="far fa-info-circle text-info ms-1"
                              v-tooltip:right="
                                'Send an email to the client confirming the booking details'
                              "
                          ></i>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <toggle-button v-model="consultation.send_reminder_emails"
                                       class="me-1"
                                       :sync="true"
                                       :color="'#03006b'" />
                        <label>
                          Appointment Reminder Emails
                          <i
                              class="far fa-info-circle text-info ms-1"
                              v-tooltip:right="
                                'Send reminder emails leading up to the booking'
                              "
                          ></i>
                        </label>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col">
                        <toggle-button v-model="consultation.send_follow_up_email"
                                       class="me-1"
                                       :sync="true"
                                       :color="'#03006b'" />
                        <label>
                          Follow Up Email
                          <i
                              class="far fa-info-circle text-info ms-1"
                              v-tooltip:right="
                                'Send a follow up email 24 hours after the booking takes place'
                              "
                          ></i>
                        </label>
                        <div
                          v-if="consultation.send_follow_up_email"
                          class="mt-3"
                        >
                          <select
                            class="form-control w-50"
                            v-model="consultation.follow_up_email_template_id"
                            required
                          >
                            <option :value="null" selected>
                              Select an Email Template
                            </option>
                            <option
                              v-for="template in emailTemplates.filter(
                                function (t) {
                                  return t.type === 'appointment';
                                }
                              )"
                              :value="template.id"
                            >
                              {{ template.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary">
                <i class="far fa-check me-1"></i>
                Confirm Consultation
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { DatePicker } from "@jaythegeek/crmtoolkit";
import { CurrencyInput } from "vue-currency-input";
import UtcFlatPickr from "../../../../globals/UtcFlatPickr";
import SelectService from "../../../../components/services/SelectService";
export default {
  props: ["user", "client", "consultationId"],
  data() {
    return {
      busy: false,
      consultation: null,
      loading: true,
      emailTemplates: [],
      blockingEvents: [],
    };
  },
  methods: {
    fetchConsultation() {
      this.$axios
          .get(process.env.VUE_APP_API_URL + "/consultations/api/" + this.consultationId)
          .then(({ data }) => {
            this.consultation = data;
            this.loading = false;
            $("#consultationConfirmModal").modal("show");
          });
    },
    confirmConsultation() {
      if (this.busy) {
        let busyError = { message: "Please wait...", color: "danger" };
        this.$EventBus.$emit("alert", busyError);
        return;
      }

      this.busy = true;

      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/consultations/confirm",
          this.consultation
        )
        .then(({ data }) => {
          this.busy = false;
          this.$EventBus.$emit("alert", data);
          this.$emit("saved");
          $("#consultationConfirmModal").modal("hide");
        });
    },
    fetchEmailTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/email-templates/api")
        .then(({ data }) => {
          this.emailTemplates = data.filter(function (template) {
            return template.user_id > 0;
          });
        });
    },
    checkDateAvailability() {
      if (!this.consultation
        || this.consultation.start === null
      ) {
        return;
      }
      return this.$axios
        .post(process.env.VUE_APP_API_URL + "/events/check-availability", {
          user_id: this.user.id,
          start: this.consultation.start,
          duration: this.consultation.service_duration,
        })
        .then(({ data }) => {
          this.blockingEvents = data;
        });
    },
  },
  created() {

    $("#consultationConfirmModal").modal("show");
    this.fetchConsultation();
    this.fetchEmailTemplates();

    $("#consultationConfirmModal").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });
  },
  computed: {},
  filters: {
    formatDate(date) {
      return moment(date).format("dddd LL @ h:mma");
    },
  },
  watch: {
    "consultation.start": function (newVal, oldVal) {
      console.log("Checking");
      this.checkDateAvailability();
    },
  },
  components: {
    SelectService,
    UtcFlatPickr,
    flatPickr,
    DatePicker,
    CurrencyInput,
  },
};
</script>

<template>
  <div class="container">
    <div v-if="client">
      <div id="trackerView">
        <div class="row mb-3">
          <div class="col-auto my-auto">
            <h5 class="mb-0 client-tab-heading">
              Health Tracker Plans
              <span v-if="selectedPlan"> - {{ selectedPlan.title }}</span>
            </h5>
          </div>
          <div class="col text-end">
            <button
              v-if="selectedPlan"
              class="btn btn-sm btn-outline-primary float-end"
              @click="selectedPlan = null"
            >
              <i class="far fa-arrow-left me-1"></i>
              Back to Client Health Tracker Overview
            </button>
            <button
              v-else-if="!showAddPlan"
              class="btn btn-sm btn-outline-primary float-end"
              @click="showAddPlan = true"
            >
              <i class="far fa-plus me-1"></i>
              New Health Tracker Plan
            </button>
            <button
              v-else-if="showAddPlan"
              class="btn btn-sm btn-outline-primary float-end"
              @click="goToTemplateBuilder"
            >
              <i class="far fa-align-justify me-1"></i>
              Health Tracker Template Builder
            </button>
          </div>
        </div>

        <!--  -->
        <hr class="my-3" />

        <div class="row mb-4">
          <div class="col my-auto">
            <new-my-mop-plan
              v-if="showAddPlan"
              :user="user"
              :client="client"
              @updated="newPlanAdded"
              @cancel="showAddPlan = false"
            ></new-my-mop-plan>

            <my-mop-list
              v-else-if="!selectedPlan"
              :user="user"
              :client="client"
              :update-required="updateRequired"
              @refreshed="updateRequired = false"
              @selected="planSelected"
            ></my-mop-list>

            <view-my-mop-plan
              v-else
              :selected-plan="selectedPlan"
            ></view-my-mop-plan>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import NewMyMopPlan from "./mymop-partials/NewMyMopPlan";
import MyMopList from "./mymop-partials/MyMopList";
import ViewMyMopPlan from "./mymop-partials/ViewMyMopPlan";
export default {
  props: ["clientObj"],
  data() {
    return {
      client: null,
      showAddPlan: false,
      updateRequired: false,
      selectedPlan: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    goToTemplateBuilder() {
      this.$router.push("/settings/tracker");
    },
    newPlanAdded() {
      this.showAddPlan = false;
      this.updateRequired = true;
    },
    planSelected(plan) {
      this.selectedPlan = plan;
    },
    async fetchClientData() {

      if(this.clientObj) {
        this.client = this.clientObj;
        return;
      }

      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.clientObj ? this.clientObj.id : this.$route.params.id;
    await this.fetchClientData();
  },
  components: {
    ViewMyMopPlan,
    MyMopList,
    NewMyMopPlan,
  },
};
</script>

<style>
</style>

<template>
  <div class="container">
    <div v-if="client">
      <div class="filesView">
        <div class="row mb-3">
          <div class="col-auto my-auto">
            <h5 class="mb-0 client-tab-heading">Files</h5>
          </div>
        </div>

        <!--  -->
        <hr class="my-3" />

        <p class="alert alert-primary">
          <i class="fa fa-info me-2"></i>
          Client Files allows you to <b>share confidential files</b> with
          clients.
        </p>

        <div class="card bg-light mb-3">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <div class="form-inline flexbox-row">
                  <input
                    type="text"
                    class="form-control me-1"
                    :class="submitted && !folderName ? 'is-invalid' : ''"
                    placeholder="Create a folder..."
                    v-model="folderName"
                    @keyup.enter="createFolder"
                  />
                  <button class="btn btn-outline-primary" @click="createFolder">
                    <i class="far fa-folder-plus"></i>
                  </button>
                </div>
              </div>
              <div class="col-auto me-auto">
                <div class="btn-group me-2">
                  <button
                    class="btn"
                    @click="listView = !listView"
                    :class="!listView ? 'btn-primary' : 'btn-outline-primary'"
                  >
                    <i class="far fa-th-large me-1"></i>
                    Grid
                  </button>
                  <button
                    class="btn"
                    @click="listView = !listView"
                    :class="listView ? 'btn-primary' : 'btn-outline-primary'"
                  >
                    <i class="far fa-list me-1"></i>
                    List
                  </button>
                </div>
                <button
                  class="btn btn-outline-primary"
                  @click="showDownloadZip"
                >
                  <i class="far fa-download me-1"></i>
                  Download ZIP
                </button>
              </div>
              <div class="col-auto ms-auto" v-if="folderOpen">
                <span class="me-2">Current folder: {{ folderOpen.name }}</span>
                <button
                  class="btn btn-outline-primary"
                  @click="upLevel()"
                  id="parentDrop"
                  @dragover.prevent
                  @drop.stop="folderDrop($event)"
                  @dragenter.stop="folderDragEnter($event)"
                  @dragleave.stop="folderDragLeave($event)"
                >
                  <i class="far fa-level-up-alt"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div v-if="!listView">
                  <grid-view
                    :files="currentFiles"
                    :folders="currentFolders"
                    @shareFolder="shareFolder"
                    @shareFile="shareFile"
                    @deleteFileShare="deleteFileShare"
                    @deleteFile="deleteFile"
                    @deleteFolder="deleteFolder"
                    @openFolder="openFolder"
                    @openFile="openFile"
                    @renameFile="renameFile"
                    @renameFolder="renameFolder"
                    @refreshed="fetchAll"
                    :compact="compact"
                  ></grid-view>
                </div>
                <div v-else>
                  <list-view
                    :compact="compact"
                    :files="currentFiles"
                    :folders="currentFolders"
                    @shareFolder="shareFolder"
                    @shareFile="shareFile"
                    @deleteFileShare="deleteFileShare"
                    @deleteFile="deleteFile"
                    @deleteFolder="deleteFolder"
                    @openFolder="openFolder"
                    @renameFile="renameFile"
                    @renameFolder="renameFolder"
                    @openFile="openFile"
                    @refreshed="fetchAll"
                  ></list-view>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vue-dropzone
          class="mb-3"
          ref="clientDropZone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-complete="fetchAll"
          @vdropzone-sending="sendingEvent"
          @vdropzone-sending-multiple="sendingEvent"
          @vdropzone-queue-complete="clearDropzone"
        ></vue-dropzone>

        <file-share-modal
          :file-to-share="fileToShare"
          :user="user"
          :client-id="client.id"
          @updated="fetchAll"
        ></file-share-modal>

        <folder-share-modal
          :folder-to-share="folderToShare"
          :user="user"
          :client-id="client.id"
          @updated="fetchAll"
        ></folder-share-modal>

        <download-zip-modal
          v-if="showDownloadZipModal"
          @closed="closeDownloadZip"
          :client="client"
        ></download-zip-modal>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import FileCard from "./files-partials/FileCard";
import FileShareModal from "./files-partials/FileShareModal";
import FolderShareModal from "./files-partials/FolderShareModal";
import FolderCard from "./files-partials/FolderCard";
import ListView from "./files-partials/ListView";
import GridView from "./files-partials/GridView";
import DownloadZipModal from "@/views/areas/clients/views/files-partials/DownloadZipModal";

export default {
  props: ["compact", "clientObj"],
  data() {
    return {
      client: null,
      folderName: "",
      folderOpen: null,
      files: [],
      folders: [],
      submitted: false,
      fileToShare: null,
      folderToShare: null,
      listView: false,
      showDownloadZipModal: false,
    };
  },
  methods: {
    folderDragEnter(event) {
      $("#parentDrop").addClass("bg-primary");
    },
    folderDragLeave(event) {
      $("#parentDrop").removeClass("bg-primary");
    },
    folderDrop(event) {
      console.log(event);
      $("#parentDrop").removeClass("bg-primary");

      const evtData = JSON.parse(event.dataTransfer.getData("text"));

      console.log(evtData);

      const emitData = {
        target_folder_id: evtData.parent_id,
        source_type: evtData.type,
        source_id: evtData.id,
      };
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/files/move",
          emitData
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.folderOpen = this.folderOpen.folder;
          this.fetchAll();
        });
    },
    upLevel() {
      this.$nextTick(() => {
        if (this.folderOpen.folder_id) {
          this.setOpenFolderById(this.folderOpen.folder_id);
        } else {
          this.folderOpen = null;
        }
      });
    },
    shareFolder(folder) {
      console.log(folder);
      this.folderToShare = folder;
      $("#shareFolderModal").modal("show");
    },
    shareFile(file) {
      this.fileToShare = file;
      $("#shareFileModal").modal("show");
    },
    deleteFileShare(file) {
      var confirmed = confirm(
        "Are you sure you wish to remove this shared file, the client will no longer see this file?"
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/stop-file-share",
            {
              file_id: file.id,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchAll();
          });
      }
    },
    deleteFile(file) {
      var confirmed = confirm(
        "Are you sure you wish to delete this file, this action cannot be undone?"
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/files/" +
              file.id +
              "/delete",
            {}
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchAll();
          });
      }
    },
    renameFile(args) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/files/" +
            args.file.id +
            "/update",
          {
            title: args.title,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchAll();
        });
    },
    openFile(file) {
      window.open(file.url);
    },
    openFolder(folder) {
      this.folderOpen = folder;
    },
    deleteFolder(folder) {
      var confirmed = confirm(
        "Are you sure you wish to delete this folder, this action cannot be undone?"
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/folders/" +
              folder.id +
              "/delete",
            {}
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchAll();
          });
      }
    },
    renameFolder(args) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/folders/" +
            args.folder.id +
            "/update",
          {
            name: args.name,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchAll();
        });
    },
    sendingEvent(file, xhr, formData) {
      if (this.folderOpen != null) {
        formData.append("folder_id", this.folderOpen.id);
      }
    },
    createFolder() {
      this.submitted = true;
      if (this.folderName) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/folders/create",
            {
              name: this.folderName,
              client_id: this.client.id,
              folder_id: this.folderOpen ? this.folderOpen.id : null,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.submitted = false;
            this.folderName = "";
            this.fetchAll();
          });
      }
    },
    fetchFiles() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/files"
        )
        .then(({ data }) => {
          this.files = data;
        });
    },
    fetchFolders() {
      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/folders"
        )
        .then(({ data }) => {
          this.folders = data;
        });
    },
    fetchAll() {
      var currentFolderId = null;

      //store current folder id if set
      if (this.folderOpen) {
        currentFolderId = this.folderOpen.id;
      }

      this.fetchFiles();
      this.fetchFolders().then(() => {
        //go back to the open folder
        if (currentFolderId) {
          this.setOpenFolderById(currentFolderId);
        }
      });
    },
    clearDropzone() {
      //clear dropzone
      this.$refs.clientDropZone.removeAllFiles(true);
    },
    setOpenFolderById(folderId) {
      //go back to the open folder
      for (let i = 0; i < this.folders.length; i++) {
        this.findFolderRecursive(this.folders[i], folderId);
      }
    },
    findFolderRecursive(obj, folderId) {
      //check this folder
      if (obj.id === folderId) {
        this.folderOpen = obj;
      } else {
        if (obj["folders"] && obj["folders"].length > 0) {
          for (let i = 0; i < obj["folders"].length; i++) {
            this.findFolderRecursive(obj["folders"][i], folderId);
          }
        }
      }
    },
    showDownloadZip() {
      this.showDownloadZipModal = true;
    },
    closeDownloadZip() {
      this.showDownloadZipModal = false;
    },
    async fetchClientData() {

      if(this.clientObj) {
        this.client = this.clientObj;
        return;
      }

      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    dropzoneOptions() {
      return {
        url:
          process.env.VUE_APP_API_URL +
          "/clients/api/" +
          this.client.id +
          "/files/upload",
        thumbnailWidth: 300,
        maxFilesize: 50, // MB
        dictDefaultMessage:
          "<i class='far fa-upload fa-4x text-primary'></i><br><br>Click here to select a file from your computer or drag n' drop a file to begin uploading",
        headers: { Authorization: "Bearer " + this.$store.getters['auth/token'] },
      };
    },
    currentFolders() {
      if (this.folderOpen) {
        const vm = this;
        return this.folders.filter(function (el) {
          return el.folder_id === vm.folderOpen.id;
        });
      } else {
        return this.folders.filter(function (el) {
          return !el.folder_id;
        });
      }
    },
    currentFiles() {
      return this.folderOpen ? this.folderOpen.files : this.files;
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();
    this.fetchAll();
  },
  components: {
    DownloadZipModal,
    GridView,
    ListView,
    FolderCard,
    FileShareModal,
    FolderShareModal,
    FileCard,
    vueDropzone: vue2Dropzone,
  },
};
</script>

<style>
</style>

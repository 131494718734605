var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"options":_vm.packages,"label":"name","clearable":false,"placeholder":"Select a package..."},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},(_vm.loading)?{key:"selected-option",fn:function(ref){
var profile_pic_url = ref.profile_pic_url;
var name = ref.name;
return [_c('i',{staticClass:"far fa-spin fa-spinner me-1"}),_vm._v(" Loading... ")]}}:{key:"selected-option",fn:function(ref){
var profile_pic_url = ref.profile_pic_url;
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(_vm.loading)?_c('span',[_vm._v("Loading...")]):_c('span',[_vm._v(" No packages available. ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="bg-black d-flex h-100 flex-column w-100">
    <div class="position-absolute top-2 ps-2 pt-2">
      <div
        v-if="displayNotification.show"
        class="permissions-prompt mb-2 d-flex"
      >
        <p
          class="rounded p-2 text-gray font-bold inline"
          :class="displayNotification.type"
        >
          {{ displayNotification.message }}
        </p>
      </div>

      <!-- TODO: Build into new socket connection @ Video.vue
      <div v-if="showPermissionsPrompt" class="permissions-prompt mb-2 d-flex">
        <p class="rounded bg-light p-2 text-gray font-bold inline">
          Please enable permissions for mic & camera
        </p>
      </div>
      -->

      <!-- TODO: Build into new socket connection @ Video.vue
      <div v-if="this.remoteFeedCount == 0" class="permissions-prompt d-flex">
        <p class="rounded bg-light p-2 text-gray font-bold inline">
          Waiting for participant to join
        </p>
      </div>
      -->

      <video
        ref="miniScreen"
        id="mini-screen-share"
        class="d-none warning z-30 mt-4 border"
        autoplay
        playsinline
        muted
        width="500px"
        height="300px"
      ></video>
    </div>

    <div
      class="
        full-screen-video
        d-flex
        flex-wrap
        justify-content-center
        align-items-center
        h-100
        bg-black
      "
    >
      <video
        ref="remoteScreenVideoElement"
        class="remote-screen-video d-none h-100 w-auto"
        autoplay
        playsinline
      ></video>

      <div class="container-fluid h-100">
        <div class="row h-100">
          <div
            ref="remoteVideosFull"
            class="
              remote-videos-full
              d-flex
              justify-content-center
              align-items-center
              h-100
            "
            :class="remoteGridCol"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="
        video-gallery
        h-28
        position-absolute
        d-flex
        flex-wrap
        align-items-center
      "
      style="bottom: 0"
    >
      <div class="control-buttons ms-2 me-auto align-items-end">
        <control-area
          :connected="connected"
          :audio-muted="audioMuted"
          :videoEnabled="videoEnabled"
          :local-screen-share="localScreenShare"
          :sharing-screen="sharingScreen"
          :screen-button-busy="screenButtonBusy"
          @toggleAudioMute="toggleAudioMute"
          @toggleVideoMute="toggleVideoMute"
          @startScreenShare="startScreenShare"
          @endScreenShare="endScreenShare"
          @endConnection="endConnection"
        ></control-area>
      </div>
      <div
        ref="remoteVideosGallery"
        id="remote-videos-container"
        class="remote-videos h-28 d-flex flex-wrap ms-auto"
      ></div>
      <div id="local-video-container" class="local-video rounded h-28">
        <video
          ref="localVideoElement"
          class="local-video rounded h-100 w-auto"
          :class="hasVideo ? '' : 'd-none'"
          autoplay
          playsinline
          muted
        ></video>
        <div
          ref="noLocalCamera"
          id="no-local-camera"
          class="
            w-24
            md:w-36
            h-100
            bg-light
            justify-content-center
            align-items-center
          "
          :class="hasVideo ? 'd-none' : 'd-flex'"
        >
          <p>No camera</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "./assets/styles/app.scss";
import { Janus } from "janus-gateway";
//
import ControlArea from "./video-partials/ControlArea.vue";

export default {
  name: "JanusVideoRoom",
  props: {
    roomData: {
      type: Object,
      default: () => ({
        id: 2150,
      }),
      validator(x) {
        return x != null;
      },
    },
    userData: {
      type: Object,
      default: () => ({
        name: "Test Testingtons",
        email: "test@xaylo.com",
      }),
      validator(x) {
        return x != null;
      },
    },
    streamEnabled: {
      type: Boolean,
      default: false,
    },
    serverUrl: {
      type: Array,
      default: [
        "wss://janus.swandoola.com/ws",
        "https://janus.swandoola.com/janus",
      ],
    },
    desktopCapturer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      role: "publisher",
      subscriberMode: false,
      janusConnection: null,
      sfutest: null,
      showPermissionsPrompt: true,
      connected: false,
      myid: null,
      mypvtid: null,
      mystream: null,
      feeds: [],
      remoteFeedCount: 0,

      //
      audioMuted: false,
      videoEnabled: false,
      sharingScreen: false,
      myScreenId: null,
      myPrivateScreenId: null,

      //

      screenVideoElement: null,
      screenShare: null,
      screenSources: [],
      localScreenShare: false,
      remoteScreenShare: false,
      selectedScreenSource: null,
      screenButtonBusy: false,
      localScreenStream: null,
      //
      screenCaptureSettings: {
        cursor: "never", // always, motion, never
      },

      displayNotification: {
        show: false,
        message: "",
        type: "bg-light",
      },
      tempDisconnect: 0,

      compatabilityChecks: {
        checkCompatabilityComplete: false,
        apiValidated: false,
        devicesFound: false,
        streamRetrievingProblem: false,
      },

      localStream: null,
      remoteStream: null,
      notificationType: "",
      notificationTimeout: null,
    };
  },
  computed: {
    hasVideo() {
      return this.localStream && !this.videoEnabled;
    },
    opaqueId() {
      return this.userData.email;
    },
    screenShareId() {
      return "***SCREEN***" + this.userData.email;
    },
    roomId() {
      return parseInt(this.roomData.id);
    },
    remoteGridCol() {
      if (this.remoteFeedCount == 1) {
        return "col";
      } else if (this.remoteFeedCount == 2) {
        return "col-6";
      } else if (this.remoteFeedCount == 3) {
        return "col-4";
      } else {
        return "col-4";
      }
    },
    miniScreen() {
      return this.$refs.miniScreen;
    },
  },
  methods: {
    // == PreFlight Check == //
    async checkCompatability() {
      try {
        let config = { audio: true, video: true };
        let _vm = this;
        let stream = await navigator.mediaDevices.getUserMedia(config);
        console.log(stream);
        this.compatabilityChecks.apiValidated = true;
        this.compatabilityChecks.checkCompatabilityComplete = true;
        this.showPermissionsPrompt = false;
        //navigator.mediaDevices.enumerateDevices().then(function (devices) {
        //  console.log(devices);
        //  _vm.devicesFound = true;
        //});
      } catch (error) {
        //alert(`${error}`);
        console.log(error);
        this.compatabilityChecks.apiValidated = false;
        this.compatabilityChecks.checkCompatabilityComplete = true;
        return false;
      }

      return true;
    },
    // === UI Handlers === //
    handleICEState(state) {
      switch (state) {
        case "failed":
        case "closed":
        case "disconnected":
          this.showNotification("Connection Status: Disconnected", "danger", 0);
          this.notificationType = "iceState";
          this.tempDisconnect = 1;
          break;
        case "connected":
          if (this.tempDisconnect) {
            this.showNotification(
              "Connection Status: Connected",
              "success",
              4000
            );
            this.notificationType = "iceState";
          }
          break;
        case "new":
        case "checking":
          if (this.tempDisconnect) {
            this.showNotification(
              "Connection Status: Checking...",
              "success",
              4000
            );
            this.notificationType = "iceState";
          }
          break;
      }
    },
    handleSlowLink(uplink, lost) {
      // uplink = false is a problem sending packets to server
      // lost is quantity of packets
      if (uplink == false) {
        this.showNotification(
          "Connection Status: Slow Connection",
          "warning"
        );
        this.notificationType = "packetsLost";
      }
    },
    // === UI Methods ==== //
    showNotification(message, type, duration = 0) {
      clearTimeout(this.notificationTimeout);

      this.displayNotification.message = message;
      this.displayNotification.type = "bg-" + type;
      this.displayNotification.show = true;
      if (duration !== 0) {
        this.notificationTimeout = setTimeout(() => (this.displayNotification.show = false), duration);
      }
    },
    hideNotification(type) {
      if (type && type == this.notificationType) {
        clearTimeout(this.notificationTimeout);
        this.notificationType = "";

        this.displayNotification.show = false;
      }
    },
    // =================== //

    toggleAudioMute() {
      this.audioMuted = !this.audioMuted;
      this.$emit('toggleAudioMute', this.audioMuted);

      // this.audioMuted = this.sfutest.isAudioMuted();
      // Janus.log((this.audioMuted ? "Unmuting" : "Muting") + " local stream...");
      // if (this.audioMuted) {
      //   this.sfutest.unmuteAudio();
      // } else {
      //   this.sfutest.muteAudio();
      // }
      // this.audioMuted = this.sfutest.isAudioMuted();
    },
    toggleVideoMute() {
      this.videoEnabled = !this.videoEnabled;
      this.$emit('toggleVideoMute', this.videoEnabled);

      // Janus.log(
      //   (this.videoEnabled ? "Enabling" : "Disabling") +
      //     " local video stream..."
      // );

      // if (this.videoEnabled) {
      //   this.$refs.localVideoElement.classList.remove("d-none");
      //   this.$refs.noLocalCamera.classList.add("d-none");
      //   this.$refs.noLocalCamera.classList.remove("d-flex");
      // } else {
      //   this.$refs.localVideoElement.classList.add("d-none");
      //   this.$refs.noLocalCamera.classList.remove("d-none");
      //   this.$refs.noLocalCamera.classList.add("d-flex");
      // }
      // this.sfutest.send({
      //   message: {
      //     request: "configure",
      //     video: this.videoEnabled,
      //   },
      // });

      // this.videoEnabled = !this.videoEnabled;
    },
    endConnection() {
      // this.janusConnection.destroy();
      window.close();
    },

    //
    initializeJanus() {
      console.log("Janus is here:", Janus);
      Janus.init({
        debug: true,
        dependencies: Janus.useDefaultDependencies(),
        callback: () => {
          console.log("Janus has been setup");
          this.connectToJanus();
        },
      });
    },

    connectToJanus() {
      this.janusConnection = new Janus({
        server: this.serverUrl,
        success: () => {
          // Done! attach to plugin XYZ
          this.attachVideoRoomPlugin();
        },
        error: (cause) => {
          // Error, can't go on...
        },
        destroyed: () => {
          // I should get rid of this
        },
      });
    },

    attachVideoRoomPlugin() {
      this.janusConnection.attach({
        plugin: "janus.plugin.videoroom",
        opaqueId: this.opaqueId,
        success: (pluginHandle) => {
          this.sfutest = pluginHandle;
          Janus.log(
            "Plugin attached! (" +
              this.sfutest.getPlugin() +
              ", id=" +
              this.sfutest.getId() +
              ")"
          );
          Janus.log("  -- This is a publisher/manager");

          this.$EventBus.$emit(
            "addReport",
            "Event: Attached VideoRoom as publisher"
          );

          this.checkJanusRoomExists();
        },
        error: (error) => {
          Janus.error("  -- Error attaching plugin...", error);
          console.log("Error attaching plugin... " + error);
        },
        consentDialog: (on) => {
          this.showPermissionsPrompt = on;
        },
        iceState: (state) => {
          Janus.log("ICE state changed to " + state);
          this.handleICEState(state);
        },
        mediaState: (medium, on) => {
          Janus.log(
            "Janus " + (on ? "started" : "stopped") + " receiving our " + medium
          );
        },
        slowLink: (uplink, lost) => {
          this.handleSlowLink(uplink, lost);
          Janus.log(
            "SlowLink Data: [uplink: " + uplink + "] [lost: " + lost + "]"
          );
        },
        webrtcState: (on) => {
          Janus.log(
            "Janus says our WebRTC PeerConnection is " +
              (on ? "up" : "down") +
              " now"
          );
        },
        onmessage: (msg, jsep) => {
          Janus.debug(" ::: Got a message (publisher) :::", msg);
          var event = msg["videoroom"];
          Janus.debug("Event: " + event);
          if (event) {
            if (event === "joined") {
              // Publisher/manager created, negotiate WebRTC and attach to existing this.feeds, if any
              this.myid = msg["id"];
              this.mypvtid = msg["private_id"];
              Janus.log(
                "Successfully joined room " +
                  msg["room"] +
                  " with ID " +
                  this.myid
              );
              if (this.subscriberMode) {
                // Should be able to see videos but not publish own feed
              } else {
                this.publishOwnFeed(true);
              }
              // Any new feed to attach to?
              if (msg["publishers"]) {
                var list = msg["publishers"];
                Janus.debug("Got a list of available publishers/feeds:", list);
                for (var f in list) {
                  var id = list[f]["id"];
                  var display = list[f]["display"];
                  var audio = list[f]["audio_codec"];
                  var video = list[f]["video_codec"];
                  Janus.debug(
                    "  >> [" +
                      id +
                      "] " +
                      display +
                      " (audio: " +
                      audio +
                      ", video: " +
                      video +
                      ")"
                  );
                  this.newRemoteFeed(id, display, audio, video);
                }
              }
            } else if (event === "destroyed") {
              // The room has been destroyed
              Janus.warn("The room has been destroyed!");
              console.log("The room has been destroyed", () => {
                window.location.reload();
              });
            } else if (event === "event") {
              // Any new feed to attach to?
              if (msg["publishers"]) {
                var list = msg["publishers"];
                Janus.debug("Got a list of available publishers/feeds:", list);
                for (var f in list) {
                  var id = list[f]["id"];
                  var display = list[f]["display"];
                  var audio = list[f]["audio_codec"];
                  var video = list[f]["video_codec"];
                  Janus.debug(
                    "  >> [" +
                      id +
                      "] " +
                      display +
                      " (audio: " +
                      audio +
                      ", video: " +
                      video +
                      ")"
                  );
                  this.newRemoteFeed(id, display, audio, video);
                }
              } else if (msg["leaving"]) {
                // One of the publishers has gone away?
                var leaving = msg["leaving"];
                Janus.log("Publisher left: " + leaving);
                var remoteFeed = null;
                for (var i = 1; i < 6; i++) {
                  if (this.feeds[i] && this.feeds[i].rfid == leaving) {
                    remoteFeed = this.feeds[i];
                    break;
                  }
                }
                if (remoteFeed != null) {
                  Janus.debug(
                    "Feed " +
                      remoteFeed.rfid +
                      " (" +
                      remoteFeed.rfdisplay +
                      ") has left the room, detaching"
                  );
                  $("#remote" + remoteFeed.rfindex)
                    .empty()
                    .hide();
                  $("#videoremote" + remoteFeed.rfindex).empty();
                  this.feeds[remoteFeed.rfindex] = null;
                  remoteFeed.detach();
                }
              } else if (msg["unpublished"]) {
                // One of the publishers has unpublished?
                var unpublished = msg["unpublished"];
                Janus.log("Publisher left: " + unpublished);
                if (unpublished === "ok") {
                  // That's us
                  this.sfutest.hangup();
                  return;
                }
                var remoteFeed = null;
                for (var i = 1; i < 6; i++) {
                  if (this.feeds[i] && this.feeds[i].rfid == unpublished) {
                    remoteFeed = this.feeds[i];
                    break;
                  }
                }
                if (remoteFeed != null) {
                  Janus.debug(
                    "Feed " +
                      remoteFeed.rfid +
                      " (" +
                      remoteFeed.rfdisplay +
                      ") has left the room, detaching"
                  );
                  $("#remote" + remoteFeed.rfindex)
                    .empty()
                    .hide();
                  $("#videoremote" + remoteFeed.rfindex).empty();
                  this.feeds[remoteFeed.rfindex] = null;
                  remoteFeed.detach();
                }
              } else if (msg["error"]) {
                if (msg["error_code"] === 426) {
                  // This is a "no such room" error: give a more meaningful description
                  console.log("the room doesnt exist", this.roomId);
                } else {
                  console.log(msg["error"]);
                }
              }
            }
          }
          if (jsep) {
            Janus.debug("Handling SDP as well...", jsep);
            this.sfutest.handleRemoteJsep({ jsep: jsep });
            // Check if any of the media we wanted to publish has
            // been rejected (e.g., wrong or unsupported codec)
            var audio = msg["audio_codec"];
            if (
              this.mystream &&
              this.mystream.getAudioTracks() &&
              this.mystream.getAudioTracks().length > 0 &&
              !audio
            ) {
              // Audio has been rejected
              console.log(
                "Our audio stream has been rejected, viewers won't hear us"
              );
            }
            var video = msg["video_codec"];
            if (
              this.mystream &&
              this.mystream.getVideoTracks() &&
              this.mystream.getVideoTracks().length > 0 &&
              !video
            ) {
              // Video has been rejected
              console.log(
                "Our video stream has been rejected, viewers won't see us"
              );
              // Hide the webcam video
              $("#myvideo").hide();
              $("#videolocal").append(
                '<div class="no-video-container">' +
                  '<i class="fa fa-video-camera fa-5 no-video-icon" style="height: 100%;"></i>' +
                  '<span class="no-video-text" style="font-size: 16px;">Video rejected, no webcam</span>' +
                  "</div>"
              );
            }
          }
        },
        onlocalstream: (stream) => {
          Janus.debug(" ::: Got a local stream :::", stream);
          this.mystream = stream;
          Janus.attachMediaStream(this.$refs.localVideoElement, stream);
          if (
            this.sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
            this.sfutest.webrtcStuff.pc.iceConnectionState !== "connected"
          ) {
            // Show connecting spinner or something?
          }
          var noLocalCameraImage = document.getElementById("no-local-camera");

          var videoTracks = stream.getVideoTracks();
          if (!videoTracks || videoTracks.length === 0) {
            // No webcam
            //  Show no camera image
            noLocalCameraImage.classList.remove("d-none");
            this.$refs.noLocalCamera.classList.add("d-flex");
          } else {
            // Hide no camera image
            // display local video
            this.$refs.localVideoElement.classList.remove("d-none");
            noLocalCameraImage.classList.add("d-none");
            this.$refs.noLocalCamera.classList.remove("d-flex");
          }

          this.connected = true;
        },
        onremotestream: (stream) => {
          // The publisher stream is sendonly, we don't expect anything here
        },
        oncleanup: () => {
          Janus.log(
            " ::: Got a cleanup notification: we are unpublished now :::"
          );
          this.mystream = null;
          // Can reconnect at this stage using
          // this.publishOwnFeed(true);
        },
      });
    },
    checkJanusRoomExists() {
      var exists = {
        request: "exists",
        room: this.roomId,
      };

      var create = {
        request: "create",
        room: this.roomId,
        publishers: 6,
        audiolevel_ext: true,
        audiolevel_event: true,
        audio_active_packets: 100,
        audio_level_average: 25,
      };

      this.sfutest.send({
        message: exists,
        success: (result) => {
          console.log("checking if room exists: ", result);
          if (result.exists) {
            this.$EventBus.$emit(
              "addReport",
              "Room Exists Already - Joining..."
            );
            this.registerLocalUser();
            return true;
          } else {
            this.$EventBus.$emit(
              "addReport",
              "Room Does Not Exist - Creating..."
            );
            this.sfutest.send({
              message: create,
              success: () => {
                this.$EventBus.$emit(
                  "addReport",
                  "Created Room with request: " + JSON.stringify(create)
                );
                this.checkJanusRoomExists();
              },
            });
          }
        },
      });
    },
    registerLocalUser() {
      var register = {
        request: "join",
        room: this.roomId,
        ptype: "publisher",
        display: this.userData.email,
      };
      this.$EventBus.$emit(
        "addReport",
        "Registered User with request: " + JSON.stringify(register)
      );
      this.sendJanusMessage(register);
    },
    sendJanusMessage(message) {
      this.sfutest.send({
        message: message,
      });
    },

    publishOwnFeed(useAudio) {
      // Publish our stream
      this.sfutest.createOffer({
        // Add data:true here if you want to publish datachannels as well
        media: {
          audioRecv: false,
          videoRecv: false,
          audioSend: useAudio,
          videoSend: true,
        }, // Publishers are sendonly
        simulcast: false,
        simulcast2: false,
        success: (jsep) => {
          Janus.debug("Got publisher SDP!", jsep);
          this.$EventBus.$emit("addReport", "Publishing Feed Successful");
          var publish = { request: "configure", audio: useAudio, video: true };
          this.sfutest.send({ message: publish, jsep: jsep });
        },
        error: (error) => {
          Janus.error("WebRTC error:", error);
          this.$EventBus.$emit("addReport", "[ERROR] [WEBRTC] " + JSON.stringify(error));
          if (useAudio) {
            this.publishOwnFeed(false);
          } else {
            console.log("WebRTC error... " + error.message);
            this.publishOwnFeed(true);
          }
        },
      });
    },

    newRemoteFeed(id, display, audio, video) {
      // A new feed has been published, create a new plugin handle and attach to it as a subscriber
      this.$EventBus.$emit("addReport", "Subscribing to video type: " + video);
      var remoteFeed = null;
      this.janusConnection.attach({
        plugin: "janus.plugin.videoroom",
        opaqueId: this.opaqueId,
        success: (pluginHandle) => {
          remoteFeed = pluginHandle;
          remoteFeed.simulcastStarted = false;
          Janus.log(
            "Plugin attached! (" +
              remoteFeed.getPlugin() +
              ", id=" +
              remoteFeed.getId() +
              ")"
          );
          Janus.log("  -- This is a subscriber");
          this.$EventBus.$emit("addReport", "Plugin attached (subscriber)");
          // We wait for the plugin to send us an offer
          var subscribe = {
            request: "join",
            room: this.roomId,
            ptype: "subscriber",
            feed: id,
            private_id: this.mypvtid,
          };
          // In case you don't want to receive audio, video or data, even if the
          // publisher is sending them, set the 'offer_audio', 'offer_video' or
          // 'offer_data' properties to false (they're true by default), e.g.:
          //    subscribe["offer_video"] = false;
          // For example, if the publisher is VP8 and this is Safari, let's avoid video
          if (
            Janus.webRTCAdapter.browserDetails.browser === "safari" &&
            (video === "vp9" || (video === "vp8" && !Janus.safariVp8))
          ) {
            if (video) video = video.toUpperCase();
            console.log(
              "Publisher is using " +
                video +
                ", but Safari doesn't support it: disabling video"
            );
            this.$EventBus.$emit("addReport", "[ERROR] [CODEC] Publisher is using " + video + " but Safari doesn't support it");
            subscribe["offer_video"] = false;
          }
          remoteFeed.videoCodec = video;
          remoteFeed.send({ message: subscribe });
        },
        error: (error) => {
          Janus.error("  -- Error attaching plugin...", error);
          console.log("Error attaching plugin... " + error);
          this.$EventBus.$emit("addReport", "[ERROR] attaching plugin failed...");
        },
        onmessage: (msg, jsep) => {
          console.log("not sure what happened", msg, jsep);
          Janus.debug(" ::: Got a message (subscriber) :::", msg);
          var event = msg["videoroom"];
          Janus.debug("Event: " + event);
          if (msg["error"]) {
            console.log(msg["error"]);
          } else if (event) {
            if (event === "attached") {
              // Subscriber created and attached
              for (var i = 1; i < 6; i++) {
                if (!this.feeds[i]) {
                  this.feeds[i] = remoteFeed;
                  remoteFeed.rfindex = i;
                  break;
                }
              }
              remoteFeed.rfid = msg["id"];
              remoteFeed.rfdisplay = msg["display"];

              Janus.log(
                "Successfully attached to feed " +
                  remoteFeed.rfid +
                  " (" +
                  remoteFeed.rfdisplay +
                  ") in room " +
                  msg["room"]
              );

              // Show the remote feed - what is the html in the rfdisplay - ah its display username!!
            } else if (event === "slow_link") {
              //this.handleSlowLink(false, 0);
              console.log("Slow connection event");
              this.$EventBus.$emit("addReport", "[EVENT] Slow Internet Connection Detected");
            } else if (event === "event") {
              // Check if we got a simulcast-related event from this publisher
            } else {
              // What has just happened?
              console.log("not sure what happened", msg, jsep);
            }
          }
          if (jsep) {
            Janus.debug("Handling SDP as well...", jsep);
            // Answer and attach
            remoteFeed.createAnswer({
              jsep: jsep,
              // Add data:true here if you want to subscribe to datachannels as well
              // (obviously only works if the publisher offered them in the first place)
              media: { audioSend: false, videoSend: false }, // We want recvonly audio/video
              success: (jsep) => {
                Janus.debug("Got SDP!", jsep);
                var body = { request: "start", room: this.roomId };
                remoteFeed.send({ message: body, jsep: jsep });
              },
              error: (error) => {
                Janus.error("WebRTC error:", error);
                console.log("WebRTC error... " + error.message);
                this.$EventBus.$emit("addReport", "[ERROR] [WEBRTC] (create answer)" + JSON.stringify(error));
              },
            });
          }
        },
        iceState: (state) => {
          Janus.log(
            "ICE state of this WebRTC PeerConnection (feed #" +
              remoteFeed.rfindex +
              ") changed to " +
              state
          );
        },
        webrtcState: (on) => {
          Janus.log(
            "Janus says this WebRTC PeerConnection (feed #" +
              remoteFeed.rfindex +
              ") is " +
              (on ? "up" : "down") +
              " now"
          );
        },
        onlocalstream: (stream) => {
          // The subscriber stream is recvonly, we don't expect anything here
        },
        onremotestream: (stream) => {
          Janus.debug(
            "Remote feed #" + remoteFeed.rfindex + ", stream:",
            stream
          );

          //

          if (remoteFeed.rfdisplay.includes("***SCREEN***")) {
            // The incoing feed is a screen
            this.screenShare = true;

            if (!remoteFeed.rfdisplay.includes(this.userData.email)) {
              this.setRemoteStream(stream, true);
            }
          } else {
            if (!remoteFeed.rfdisplay.includes(this.userData.email)) {
              this.setRemoteStream(stream, false);
              // Janus.attachMediaStream(actualVideoEl, stream);
            }
          }

          var videoTracks = stream.getVideoTracks();
          if (!videoTracks || videoTracks.length === 0) {
            // No remote video
          } else {
          }
        },
        oncleanup: () => {
          Janus.log(
            " ::: Got a cleanup notification (remote feed " + id + ") :::"
          );
          if (remoteFeed.spinner) remoteFeed.spinner.stop();
          remoteFeed.spinner = null;
          var remoteVideoDiv = document.getElementById(
            "remote-video-div-" + remoteFeed.rfindex
          );
          if (remoteVideoDiv) {
            remoteVideoDiv.remove();
            this.remoteFeedCount--;
          }

          if (remoteFeed.rfdisplay.includes("***SCREEN***")) {
            this.$refs.remoteVideosFull.classList.remove(
              "screen-sharing-active"
            );

            this.$refs.remoteVideosFull.classList.add("h-100");

            var allRemoteVideos = document.getElementsByClassName(
              "remote-video-player"
            );
            allRemoteVideos.forEach((v) => {
              v.classList.remove("h-28");
              v.classList.add("h-100");
            });

            this.$refs.remoteScreenVideoElement.classList.add("d-none");
          }

          remoteFeed.simulcastStarted = false;
        },
      });
    },

    // Screen sharing

    async startScreenShare() {
      this.$EventBus.$emit("consultation:ScreenSharingStarted");
      if (this.isElectron) {
        this.enableScreenShareElectron();
      } else {
        var displayMediaOptions = {
          video: {
            cursor: this.screenCaptureSettings.cursor,
          },
          audio: false,
        };

        var stream = await navigator.mediaDevices.getDisplayMedia(
          displayMediaOptions
        );
        this.selectScreenSource(stream);
      }
    },

    async selectScreenSource(stream) {
      this.localScreenShare = true;
      this.publishScreen(stream);
      this.screenSources = [];
    },

    publishScreen(stream) {
      // Close the notes tab

      this.listenForScreenShareEnd(stream);
      this.$emit('startScreenShare', stream);

      this.localScreenStream = stream;
      this.miniScreen.srcObject = stream;
      // this.miniScreen.classList.remove("d-none");

      this.screenVideoElement = document.createElement("video");
      this.screenVideoElement.srcObject = stream;
      this.screenVideoElement.setAttribute('autoplay', true);
      this.screenVideoElement.setAttribute('playsinline', true);

      // this.registerScreenUsername();

      // this.janusConnection.attach({
      //   plugin: "janus.plugin.videoroom",
      //   opaqueId: this.screenShareId,
      //   success: (pluginHandle) => {
      //     this.screenConnection = pluginHandle;
      //     var subscribe = {
      //       request: "join",
      //       room: this.roomId,
      //       ptype: "publisher",
      //       private_id: this.myPrivateScreenId,
      //       display: this.screenShareId,
      //       quality: 0,
      //     };

      //     this.screenConnection.send({
      //       message: subscribe,
      //     });
      //   },
      //   error: (error) => {
      //     Janus.error("  -- Error attaching plugin...", error);
      //     console.log("Error attaching plugin... " + error);
      //   },
      //   onmessage: (msg, jsep) => {
      //     Janus.debug(" ::: Got a message (publisher) :::", msg);
      //     var event = msg["videoroom"];
      //     Janus.debug("Event: " + event);
      //     if (event) {
      //       if (event === "joined") {
      //         this.myScreenId = msg["id"];
      //         this.myPrivateScreenId = msg["private_id"];
      //         Janus.log(
      //           "Successfully joined room " +
      //             msg["room"] +
      //             " with ID " +
      //             this.myScreenId
      //         );
      //         if (this.role === "publisher") {
      //           this.screenConnection.createOffer({
      //             stream: stream,
      //             media: {
      //               video: true,
      //               audioSend: true,
      //               videoRecv: false,
      //             }, // Screen sharing Publishers are sendonly
      //             success: (jsep) => {
      //               Janus.debug("Got publisher SDP!", jsep);
      //               var publish = {
      //                 request: "configure",
      //                 audio: true,
      //                 video: true,
      //               };
      //               this.screenConnection.send({
      //                 message: publish,
      //                 jsep: jsep,
      //               });
      //             },
      //             error: (error) => {
      //               Janus.error("WebRTC error:", error);
      //               console.log("WebRTC error... " + error.message);
      //             },
      //           });
      //         }
      //       }
      //     }
      //     if (jsep) {
      //       Janus.debug("Handling SDP as well...", jsep);
      //       this.screenConnection.handleRemoteJsep({
      //         jsep: jsep,
      //       });
      //     }
      //   },
      //   onlocalstream: (stream) => {
      //     Janus.debug(" ::: Got a local screen stream :::", stream);
      //     this.localScreenStream = stream;
      //     this.miniScreen.srcObject = stream;
      //     // this.miniScreen.classList.remove("d-none");

      //     this.screenVideoElement = document.createElement("video");
      //     this.screenVideoElement.srcObject = stream;
      //     this.screenVideoElement.autoplay = true;

      //     if (
      //       this.sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
      //       this.sfutest.webrtcStuff.pc.iceConnectionState !== "connected"
      //     ) {
      //       console.log("connecting local video");
      //       // show a spinner or something
      //     }
      //     var videoTracks = stream.getVideoTracks();
      //     if (!videoTracks || videoTracks.length === 0) {
      //       // No webcam
      //     }
      //   },
      //   webrtcState: (on) => {
      //     if (on) {
      //       this.screenConnection.send({
      //         message: {
      //           request: "configure",
      //           bitrate: 0,
      //         },
      //       });
      //     }
      //   },
      // });
    },

    listenForScreenShareEnd(stream) {
      stream.getVideoTracks()[0].addEventListener("ended", () => {
        console.log("Stop button pressed in browser");
        this.endScreenShare();
      });
    },

    endScreenShare() {
      this.screenButtonBusy = true;

      this.$emit('endScreenShare', {success: () => {
        this.localScreenShare = false;
        this.screenButtonBusy = false;

        this.miniScreen.srcObject = null;
        this.miniScreen.classList.add("d-none");
        this.screenVideoElement = null;
      }});

      // var unpublish = {
      //   request: "unpublish",
      // };
      // this.screenConnection.send({
      //   message: unpublish,
      //   success: () => {
      //     this.localScreenShare = false;
      //     this.screenButtonBusy = false;

      //     this.localScreenStream.getTracks().forEach((track) => track.stop());

      //     this.localScreenStream = null;
      //     this.miniScreen.srcObject = null;
      //     this.miniScreen.classList.add("d-none");
      //     this.screenShare = null;
      //     this.screenVideoElement = null;
      //   },
      // });
    },

    registerScreenUsername() {
      // Create a new room
      Janus.log("Screen sharing session created: " + this.roomId);
      var register = {
        request: "join",
        room: this.roomId,
        ptype: "publisher",
        display: this.screenShareId,
        quality: 0,
      };

      this.sfutest.send({
        message: register,
      });
    },

    setLocalStream(stream) {
      this.localStream = stream;
      this.$refs.localVideoElement.srcObject = this.localStream;
    },
    setRemoteStream(stream, isScreen) {
      if (isScreen) {
        if (stream) {
          this.$refs.remoteScreenVideoElement.srcObject = stream;
          this.$refs.remoteScreenVideoElement.classList.remove("d-none");

          this.$refs.remoteVideosFull.classList.add(
            "screen-sharing-active"
          );

          this.$refs.remoteVideosFull.classList.remove("h-100");
          var allRemoteVideos = document.getElementsByClassName(
            "remote-video-player"
          );
          allRemoteVideos.forEach((v) => {
            v.classList.remove("h-100");
            v.classList.add("h-28");
          });
          this.screenShare = true;
        } else {
          this.$refs.remoteVideosFull.classList.remove(
            "screen-sharing-active"
          );

          this.$refs.remoteVideosFull.classList.add("h-100");

          var allRemoteVideos = document.getElementsByClassName(
            "remote-video-player"
          );
          allRemoteVideos.forEach((v) => {
            v.classList.remove("h-28");
            v.classList.add("h-100");
          });

          this.screenShare = false;
          this.$refs.remoteScreenVideoElement.classList.add("d-none");
        }
      } else {
        var remoteVideoExists = document.getElementById("remote-video-div");

        if (stream) {
          if (!remoteVideoExists) {
            var divAppend = document.createElement("div");
            divAppend.classList +=
              "d-flex justify-content-center  h-100";
            divAppend.id = "remote-video-div";

            var actualVideoEl = document.createElement("video");
            actualVideoEl.ref = "remote-video";
            actualVideoEl.id = "remote-video";
            actualVideoEl.classList +=
              "remote-video-player mh-100 h-100 w-auto";
            actualVideoEl.setAttribute('autoplay', true);
            actualVideoEl.setAttribute('playsinline', true);
            actualVideoEl.srcObject = stream;

            divAppend.appendChild(actualVideoEl);

            this.$refs.remoteVideosFull.appendChild(divAppend);
            this.remoteFeedCount++;
          } else {
            var actualVideoEl = document.getElementById("remote-video");
            actualVideoEl.srcObject = stream;
          }
        } else {
          if (remoteVideoExists) {
            remoteVideoExists.remove();
            this.remoteFeedCount--;
          }
        }
      }
    },
    getAudioStatus() {
      return this.audioMuted;
    },
    getVideoStatus() {
      return this.videoEnabled;
    },
    setGetUserMediaError() {
      this.checkCompatability.streamRetrievingProblem = true;
    }
  },
  mounted() {
    /*if (this.checkCompatability()) {
      this.initializeJanus();
    }*/
  },

  components: {
    ControlArea,
  },
};
</script>

<style lang="scss">
#router-view {
  padding: 0 !important;
}
.screen-sharing-active {
  position: absolute;
  bottom: 0.75rem;
  right: 11rem;
  width: auto;
  max-height: 10rem;
  z-index: 10;
}

video.local-video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}

.h-28 {
  height: 6rem;
}
</style>
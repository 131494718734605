<template>
  <div v-if="selectedPlan">
    <div class="row mt-4">
      <div class="col">
        <ul class="list-group w-auto">
          <li v-for="question in selectedPlan.questions"
              class="list-group-item cursor-pointer"
              :class="selectedQuestion && selectedQuestion.id === question.id ? 'bg-light' : ''"
              @click="selectQuestion(question)">
            {{ question.question }}
            <span class="float-end">
              <i v-if="selectedQuestion && selectedQuestion.id === question.id"
                 class="far fa-caret-down"></i>
              <i v-else class="far fa-caret-right"></i>
            </span>
            <div v-if="selectedQuestion && selectedQuestion.id === question.id">
              <div class="row mt-3">
                <div class="col">
                  <mymop-bar-chart
                          :data="selectedQuestionChartData"
                          :height="300"
                          :styles="chartStyles"></mymop-bar-chart>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <h5>Requests by Date</h5>
        <ul class="list-group w-auto">
          <li class="list-group-item">
            {{selectedPlan.next_request_date | formatDate }}
            <span class="text-capitalize badge bg-light ms-2">
              Upcoming Request
            </span>
          </li>
          <li class="list-group-item cursor-pointer"
              :class="selectedRequest && selectedRequest.id === request.id ? 'bg-light' : ''"
              v-for="request in selectedPlan.requests"
              @click="selectRequest(request)">
            {{ request.created_at | formatDate }}
            <span class="text-capitalize badge ms-2"
                  :class="request.status === 'sent' ? 'bg-info' : 'bg-primary'">
              {{ request.status }}
            </span>
            <span class="float-end">
              <i v-if="selectedRequest && selectedRequest.id === request.id"
                 class="far fa-caret-down"></i>
              <i v-else class="far fa-caret-right"></i>
            </span>
            <div v-if="selectedRequest && selectedRequest.id === request.id">
              <div class="row mt-3">
                <div class="col-8 mx-auto">
                  <div class="card" v-if="selectedRequest.client_response_date">
                    <div class="card-body">
                      <div class="row mb-1" v-for="(item, index) in selectedRequest.questions">
                        <div class="col">
                          <p>{{item.question.question}}</p>
                          <vue-slider
                                  class="mb-5 mx-2"
                                  :min="0"
                                  :max="10"
                                  :interval="1"
                                  :marks="true"
                                  v-model="item.client_response"
                                  :disabled="true"
                          />
                          <p v-if="item.additional_text">
                            {{item.additional_text}}
                          </p>
                          <p v-if="item.client_comment">
                            Comment: <br />
                            {{item.client_comment}}
                          </p>
                          <hr v-if="index !== selectedRequest.questions.length - 1" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="text-center">
                    <p>Client has not responded yet.</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import MymopBarChart from "./MymopBarChart";
  export default {
  props: ["selectedPlan"],
  data() {
    return {
      mymopPlans: [],
      selectedRequest: null,
      selectedQuestion: null,
      chartStyles: {
        'height': '200px',
        'position': 'relative',
      }
    };
  },
  methods: {
    selectRequest(request){
      if (this.selectedRequest && this.selectedRequest.id === request.id){
        this.selectedRequest = null;
      } else {
        this.selectedRequest = request;
      }
    },
    selectQuestion(question){
      if (this.selectedQuestion && this.selectedQuestion.id === question.id){
        this.selectedQuestion = null;
      } else {
        this.selectedQuestion = question;
      }
    },
  },
  mounted() {
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    }
  },
  watch: {
  },
  computed: {
    selectedQuestionChartData() {
      let labels = [];
      let data = [];
      let comments = [];

      for (let i=0; i<this.selectedQuestion.requests.length; i++){
        labels[i] = moment(this.selectedQuestion.requests[i].created_at).format("LL");
      }

      for (let i=0; i<this.selectedQuestion.requests.length; i++){
        data[i] = this.selectedQuestion.requests[i].client_response;
      }

      for (let i=0; i<this.selectedQuestion.requests.length; i++){
        comments[i] = this.selectedQuestion.requests[i].client_comment;
      }

      return {
        labels: labels,
        comments: comments,
        datasets: [
          {
            label: '',
            backgroundColor: '#9998C1',
            data: data,
          }
        ]
      };
    },
  },
  components: {
    MymopBarChart,
    VueSlider
  }
};
</script>

<style>
</style>

<template>
  <div>
    <div class="row mb-3" v-if="folders && folders.length > 0">
      <div class="col-3 text-center" v-for="folder in folders">
        <folder-card
          :folder="folder"
          @openFolder="openFolder"
          @shareFolder="shareFolder"
          @deleteFolder="deleteFolder"
          @renameFolder="renameFolder"
          @refreshed="refreshed"
        ></folder-card>
      </div>
    </div>
    <div class="row mb-3" v-if="files && files.length > 0">
      <div class="col-3 text-center" v-for="file in files">
        <file-card
          :file="file"
          :compact="compact"
          @realTimeShare="realTimeShare"
          @shareFile="shareFile"
          @deleteFileShare="deleteFileShare"
          @deleteFile="deleteFile"
          @renameFile="renameFile"
          @openFile="openFile"
          @refreshed="refreshed"
        ></file-card>
      </div>
    </div>
    <div
      class="row"
      v-if="
        (!files || files.length === 0) && (!folders || folders.length === 0)
      "
    >
      <div class="col text-center">
        <p><i class="far fa-info-circle fa-4x text-primary"></i></p>
        <p class="mb-0">
          No files found. Upload a file below or create a new folder above.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FileCard from "./FileCard";
import FolderCard from "./FolderCard";
export default {
  props: ["folders", "files", "compact"],
  data() {
    return {};
  },
  methods: {
    realTimeShare(file) {
      this.$EventBus.$emit("realTimeShareFile", file);
    },
    shareFile(file) {
      this.$emit("shareFile", file);
    },
    deleteFileShare(file) {
      this.$emit("deleteFileShare", file);
    },
    deleteFile(file) {
      this.$emit("deleteFile", file);
    },
    shareFolder(folder) {
      this.$emit("shareFolder", folder);
    },
    deleteFolder(folder) {
      this.$emit("deleteFolder", folder);
    },
    openFolder(folder) {
      this.$emit("openFolder", folder);
    },
    openFile(file) {
      this.$emit("openFile", file);
    },
    renameFile(args) {
      this.$emit("renameFile", args);
    },
    renameFolder(folder) {
      this.$emit("renameFolder", folder);
    },
    refreshed() {
      this.$emit("refreshed");
    },
  },
  computed: {},
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  mounted() {},
  components: {
    FolderCard,
    FileCard,
  },
};
</script>

<style>
</style>

<template>
  <div>
    <forms-tab :user="user" :clientObj="client" :compact="true" :videoView="true"></forms-tab>
  </div>
</template>

<script>
import FormsTab from "../../clients/views/Forms";

export default {
  props: ["client", "user"],
  filters: {
    dateFormat(date) {
      return moment.utc(date).format("LLL");
    },
  },
  methods: {
    close() {
      this.$emit("closed");
    },
  },
  components: {
    FormsTab,
  },
};
</script>

<style>
</style>

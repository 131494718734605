<template>
  <!-- SHARE MODAL-->
  <div
    class="modal fade"
    id="shareFileModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="expandedTaskModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title client-tab-heading"
            id="expandedTaskModalTitle"
          >
            Share File
          </h5>
        </div>
        <div class="modal-body" v-if="fileToShare">
          <div class="row mb-3">
            <div class="col">
              <p>
                You are about to share {{ fileToShare.title }}. This will be
                visible to the client in their client portal.
              </p>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="send_notifications"
                  v-model="sendClientNotifications"
                  :checked="sendClientNotifications"
                />
                <label class="custom-control-label" for="send_notifications">
                  Send client notifications
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
            @click="performFileShare"
          >
            Confirm File Share
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "clientId", "fileToShare"],
  data() {
    return {
      sendClientNotifications: this.defaultSendClientNotifications(),
    };
  },
  methods: {
    performFileShare() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.clientId +
            "/share-file-with-client",
          {
            file_id: this.fileToShare.id,
            send_client_notifications: this.sendClientNotifications,
          }
        )
        .then(({ data }) => {
          this.fileToShare = null;
          $("#shareFileModal").modal("hide");
          this.$EventBus.$emit("alert", data);
          this.$emit("updated");
        });
    },
    defaultSendClientNotifications() {
      return (
        this.user.marketing_email_settings.enable_client_system_emails &&
        this.user.marketing_email_settings.enable_files_emails
      );
    },
  },
  computed: {},
  filters: {},
  mounted() {},
  components: {},
};
</script>

<style>
</style>

<template>
  <div
    class="modal fade show mx-auto noprint"
    id="practitioner-form-response-modal"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg noprint" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title client-tab-heading">Complete client form</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <div v-if="clientForm">
                <div class="row">
                  <div class="col my-auto">
                    <h5 class="mb-2">{{ clientForm.title }}</h5>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <p class="alert alert-primary mb-3 mt-1">
                      Please complete the following form sections. Your changes
                      are saved automatically, so you can pause and come back at
                      any time.
                    </p>
                  </div>
                  <div class="col-3 text-center">
                    <button
                      class="
                        btn btn-lg
                        bg-success
                        mt-1
                        mb-2
                        text-white
                        align-center
                      "
                      @click="saveProgress()"
                    >
                      Save Progress
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="accordion" id="sectionsAccordion">
                      <div
                        v-for="section in clientForm.sections"
                        class="shadow"
                      >
                        <div class="card sticky-top fixed-top shadow-none">
                          <div
                            class="card-header ps-1 pe-3"
                            :id="'heading' + section.id"
                          >
                            <h2 class="mb-0">
                              <button
                                class="
                                  btn btn-link
                                  fw-bold
                                  w-75
                                  text-start
                                "
                                type="button"
                                data-toggle="collapse"
                                :data-target="'#collapse' + section.id"
                                aria-expanded="false"
                                :aria-controls="'collapse' + section.id"
                                @click="scrollTo('collapse' + section.id)"
                              >
                                <i class="far fa-caret-down me-2"></i>
                                {{ section.title }}
                              </button>
                              <span class="small float-end">
                                <small>({{ getNumberOfQs(section) }})</small>
                              </span>
                            </h2>
                          </div>
                        </div>
                        <div class="card">
                          <div
                            :id="'collapse' + section.id"
                            class="collapse"
                            :aria-labelledby="'heading' + section.id"
                            data-parent="#sectionsAccordion"
                          >
                            <div class="card-body" :ref="'body-' + section.id">
                              <form
                                :id="'form-render-' + section.id"
                                @change="saveResponse(section)"
                                @submit.prevent="saveResponse(section)"
                              ></form>
                              <div v-if="section.scored">
                                <h5 class="fw-bold">
                                  Section Score Total: {{ section.score }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary ms-auto"
            @click="saveProgress"
            :disabled="busySaving"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          >
            <busy :visible="busySaving" button>Save</busy>
            <i v-if="!busySaving" class="far fa-save me-1"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
require("jquery-ui-bundle");
require("formBuilder/dist/form-render.min.js");
export default {
  props: ["clientForm"],
  data() {
    return {
      busySaving: false,
      sectionRenderers: [],
      unsavedChanges: false,
    };
  },
  mounted() {
    $("#practitioner-form-response-modal").modal("show");
    $("#consultationBookModal").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });
    this.initSectionRenderers();
  },
  methods: {
    initSectionRenderers() {
      for (let i = this.clientForm.sections.length - 1; i >= 0; i--) {
        const section = this.clientForm.sections[i];

        setTimeout(() => {
          var formRenderOptions = {
            dataType: "json",
            formData: section.client_response,
          };

          this.sectionRenderers.push({
            section_id: section.id,
            renderer: $("#form-render-" + section.id).formRender(
              formRenderOptions
            ),
          });
        }, 500);
      }
    },
    saveProgress() {
      this.clientForm.sections.forEach((s) => this.saveResponse(s));
    },
    saveResponse(section) {
      this.unsavedChanges = true;
      this.busySaving = true;
      if (this.clientForm.status !== "response_received") {
        const renderer = this.sectionRenderers.filter((renderer) => {
          return renderer.section_id === section.id;
        })[0].renderer;

        if (section.scored) {
          var score = 0;
          renderer.userData.forEach((field) => {
            if (field.type === "number") {
              if (field.userData && parseInt(field.userData[0])) {
                score = score + parseInt(field.userData[0]);
              }
            }
            if (field.type === "radio-group") {
              if (field.userData && parseInt(field.userData[0])) {
                score = score + parseInt(field.userData[0]);
              }
            }
          });
          section.score = score;
        }

        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/forms/api/response/" +
              this.clientForm.id +
              "/save",
            {
              client_form_section_id: section.id,
              form_data: renderer.userData,
              score: section.score,
            }
          )
          .then(({ data }) => {
            if (this.clientForm.status === "sent") {
              this.clientForm.status = "in_progress";
            }
            this.$EventBus.$emit("alert", data);
            this.unsavedChanges = false;
            this.busySaving = false;
          })
          .catch((error) => {
            this.unsavedChanges = true;
            this.$EventBus.$emit("alert", {
              color: "danger",
              message: "Error. Unable to save changes.",
            });
            this.busySaving = false;
          });
      } else {
        this.$EventBus.$emit("alert", {
          color: "danger",
          message: "Response already sent. Unable to save changes.",
        });
        this.busySaving = false;
      }
    },
    getNumberOfQs(section) {
      var count = 0;

      const options = JSON.parse(section.form_options);
      if (options) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].type !== "header" && options[i].type !== "paragraph") {
            count++;
          }
        }
      }
      return count;
    },
    scrollTo(elementId) {
      let vm = this;
      let container;
      setTimeout(() => {
        container = vm.$el.querySelector("#" + elementId);
        if (container) {
          scrollTo(0, 0);
          setTimeout(() => {
            //container.scrollIntoView();
            const y = container.getBoundingClientRect().top - 50;
            scrollTo({ top: y, behavior: "smooth" });
          }, 50);
        }
      }, 300);
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
};
</script>

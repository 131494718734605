<template>
  <div class="file-card cursor-pointer rounded mb-3">
    <span class="client-upload-icon" v-if="file.uploaded_by_client">
      <i class="far fa-user text-primary"></i>
    </span>
    <div
      @click="openFile(file)"
      draggable="true"
      @dragstart="fileDragStart"
      v-tooltip.bottom="toolTipText"
      class="pt-2 rounded"
    >
      <i class="far fa-4x text-primary" :class="fileIcon"></i>
      <p v-if="!editThisFile" class="mt-2">{{ file.title }}</p>
      <input
        v-else
        type="text"
        class="form-control my-2"
        autofocus
        :value="file.title"
        @change="renameFile(file, $event)"
        @click.stop
      />
    </div>

    <div class="row text-center">
      <span class="mx-auto">
        <i
          class="far fa-edit text-secondary cursor-pointer mx-1"
          v-tooltip.bottom="'Rename file'"
          @click.stop="editThisFile = !editThisFile"
        ></i>

        <i
          class="far fa-share-square text-primary cursor-pointer mx-1"
          v-if="!file.share"
          v-tooltip.bottom="'Share file to client portal'"
          @click.stop="shareFile(file)"
        ></i>

        <i
          class="far fa-share-square text-danger cursor-pointer mx-1"
          v-if="file.share"
          v-tooltip.bottom="
            'Remove share from client portal (this does not delete the file for you)'
          "
          @click.stop="deleteFileShare(file)"
        ></i>

        <i
          class="far fa-trash text-danger cursor-pointer mx-1"
          v-tooltip.bottom="'Delete File'"
          @click.stop="deleteFile(file)"
        ></i>
      </span>
    </div>
    <button
      class="btn btn-primary btn-block mt-2"
      v-if="compact && fileType == 'pdf'"
      @click.stop="realTimeShare(file)"
    >
      Real-time share with client
    </button>
  </div>
</template>

<script>
import getClassNameForExtension from "font-awesome-filetypes";
export default {
  props: ["file", "compact"],
  data() {
    return {
      editThisFile: false,
    };
  },
  methods: {
    realTimeShare(file) {
      this.$EventBus.$emit("realTimeShareFile", file);
    },
    deleteFileShare(file) {
      this.$emit("deleteFileShare", file);
    },
    shareFile(file) {
      this.$emit("shareFile", file);
    },
    deleteFile(file) {
      this.$emit("deleteFile", file);
    },
    renameFile(file, event) {
      this.$emit("renameFile", { file: file, title: event.target.value });
      this.editThisFile = false;
    },
    openFile(file) {
      this.$emit("openFile", file);
    },
    fileDragStart(event) {
      event.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "file",
          id: this.file.id,
          folder_id: this.file.folder ? this.file.folder.id : null,
          parent_id: this.file.folder ? this.file.folder.folder_id : null,
        })
      );
    },
  },
  computed: {
    toolTipText() {
      var ret = "";
      if (this.file.uploaded_by_client) {
        ret = ret + "Uploaded by client <br />";
      }
      ret =
        ret + "Uploaded " + moment(this.file.created_at).format("L") + "<br />";
      if (!this.file.uploaded_by_client && this.file.share) {
        ret =
          ret +
          "Shared " +
          moment(this.file.share.created_at).format("L") +
          "<br />";
      }
      return ret;
    },
    fileType() {
      if (this.file.url) {
        return this.file.url.split(".").pop();
      } else {
        return "";
      }
    },
    fileIcon() {
      var icon = "fa-file";
      if (this.file.url) {
        const extension = this.file.url.split(".").pop();
        icon = getClassNameForExtension(extension);
      }

      if (this.file.share || this.file.uploaded_by_client) {
        return icon + " text-primary";
      } else {
        return icon + " text-muted";
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  mounted() {},
  components: {},
};
</script>

<style>
.client-upload-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.file-card:hover {
  background-color: #f4f6f6 !important;
}
</style>

<template>
  <div>
    <div
      class="row"
      v-if="
        (!files || files.length === 0) && (!folders || folders.length === 0)
      "
    >
      <div class="col text-center">
        <p><i class="far fa-info-circle fa-4x text-primary"></i></p>
        <p class="mb-0">
          No files found. Upload a file below or create a new folder above.
        </p>
      </div>
    </div>
    <table v-else class="table table-hover">
      <thead>
        <th scope="col" style="border-top: none">Filename</th>
        <th scope="col" style="border-top: none">Uploaded</th>
        <th scope="col" style="border-top: none" class="text-end"></th>
      </thead>
      <tbody>
        <tr
          v-for="folder in folders"
          :id="'folder-row-' + folder.id"
          class="cursor-pointer"
          @click="openFolder(folder)"
          draggable="true"
          @dragover.prevent
          @dragstart="folderDragStart($event, folder)"
          @drop.stop="folderDrop($event, folder)"
          @dragenter.stop="folderDragEnter($event, folder)"
          @dragleave.stop="folderDragLeave($event, folder)"
        >
          <td>
            <i class="far fa-folder text-info me-1"></i>
            <span v-if="editFolder !== folder.id">{{ folder.name }}</span>
            <span v-else>
              <input
                type="text"
                class="form-control form-control-sm my-2 d-inline w-auto"
                :value="folder.name"
                @change="renameFolder(folder, $event)"
                @click.stop
                autofocus
              />
            </span>
          </td>
          <td>{{ folder.created_at | formatDate }}</td>
          <td class="text-end">
            <i
              class="far fa-edit text-secondary cursor-pointer mx-1"
              v-tooltip.bottom="'Rename Folder'"
              @click.stop="editFolder = editFolder ? null : folder.id"
            ></i>
            <i
              class="far fa-trash text-danger cursor-pointer mx-1"
              v-tooltip.bottom="'Delete Folder'"
              @click.stop="deleteFolder(folder)"
            ></i>
          </td>
        </tr>
        <tr
          v-for="file in files"
          class="cursor-pointer"
          @click="openFile(file)"
          draggable="true"
          @dragstart="fileDragStart($event, file)"
        >
          <td>
            <i class="far text-primary me-2" :class="getFileIcon(file)"></i>
            <span v-if="editFile !== file.id">{{ file.title }}</span>
            <span v-else>
              <input
                type="text"
                class="form-control form-control-sm my-2 d-inline w-auto"
                :value="file.title"
                @change="renameFile(file, $event)"
                @click.stop
                autofocus
              />
            </span>
          </td>
          <td>{{ file.created_at | formatDate }}</td>
          <td class="text-end">
            <span
              v-if="file.share || file.uploaded_by_client"
              class="badge bg-primary"
              >Shared with Client</span
            >
            <i
              class="far fa-edit text-secondary cursor-pointer mx-1"
              v-tooltip.bottom="'Rename file'"
              @click.stop="editFile = editFile ? null : file.id"
            ></i>
            <i
              class="far fa-share-square text-primary cursor-pointer mx-1"
              v-if="!file.share"
              v-tooltip.bottom="'Share file to client portal'"
              @click.stop="shareFile(file)"
            ></i>

            <i
              class="far fa-share-square text-primary cursor-pointer mx-1"
              v-if="compact"
              v-tooltip.bottom="'Real-time share this file'"
              @click.stop="realTimeShare(file)"
            ></i>

            <i
              class="far fa-share-square text-danger cursor-pointer mx-1"
              v-if="file.share"
              v-tooltip.bottom="
                'Remove share from client portal (this does not delete the file for you)'
              "
              @click.stop="deleteFileShare(file)"
            ></i>
            <i
              class="far fa-trash text-danger cursor-pointer mx-1"
              v-tooltip.bottom="'Delete File'"
              @click.stop="deleteFile(file)"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import getClassNameForExtension from "font-awesome-filetypes";
export default {
  props: ["folders", "files"],
  data() {
    return {
      editFolder: null,
      editFile: null,
    };
  },
  methods: {
    realTimeShare(file) {
      this.$EventBus.$emit("realTimeShareFile", file);
    },
    shareFile(file) {
      this.$emit("shareFile", file);
    },
    deleteFileShare(file) {
      this.$emit("deleteFileShare", file);
    },
    deleteFile(file) {
      this.$emit("deleteFile", file);
    },
    deleteFolder(folder) {
      this.$emit("deleteFolder", folder);
    },
    openFolder(folder) {
      this.$emit("openFolder", folder);
    },
    openFile(file) {
      this.$emit("openFile", file);
    },
    renameFile(file, event) {
      this.$emit("renameFile", { file: file, title: event.target.value });
      this.editFile = null;
    },
    renameFolder(folder, event) {
      this.$emit("renameFolder", { folder: folder, name: event.target.value });
      this.editFolder = null;
    },
    getFileIcon(file) {
      const extension = file.url.split(".").pop();
      return getClassNameForExtension(extension);
    },
    fileDragStart(event, file) {
      event.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "file",
          id: file.id,
        })
      );
    },
    folderDragStart(event, folder) {
      event.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "folder",
          id: folder.id,
        })
      );
    },
    folderDragEnter(event, folder) {
      $("#folder-row-" + folder.id).addClass("bg-primary");
    },
    folderDragLeave(event, folder) {
      $("#folder-row-" + folder.id).removeClass("bg-primary");
    },
    folderDrop(event, folder) {
      $("#folder-row-" + folder.id).removeClass("bg-primary");
      const evtData = JSON.parse(event.dataTransfer.getData("text"));
      console.log(
        "Move " + evtData.type + " #" + evtData.id + " to folder " + folder.name
      );

      if (folder.id !== evtData.id) {
        const emitData = {
          target_folder_id: folder.id,
          source_type: evtData.type,
          source_id: evtData.id,
        };
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client +
              "/files/move",
            emitData
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$emit("refreshed");
          });
      }
    },
  },
  computed: {},
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  mounted() {},
  components: {},
};
</script>

<style>
</style>

<template>
  <div>
    <mini-client-view :clientObj="client" :user="user"/>
  </div>
</template>

<script>
import MiniClientView from '../../health-reports/MiniClientView.vue'
export default {
  props: ["client", "user"],
  components: { MiniClientView },
  
}
</script>

<style lang="sass" scoped>

</style>
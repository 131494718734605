<template>
  <div class="container">
    <div v-if="client">
      <div id="diaryView">
        <div class="row mb-3">
          <div class="col my-auto">
            <h5 class="mb-0 client-tab-heading">Health Diary</h5>
          </div>
        </div>

        <!--  -->
        <hr class="my-3" />

        <div class="row mb-3">
          <div class="col">
            <p class="alert alert-primary">
              <i class="far fa-info me-2"></i>View your client's Health Diary
              below. Clients can add entries to their Health Diary via their
              Client Portal.
            </p>
          </div>
        </div>

        <busy :visible="loading" />

        <div v-if="!loading">
          <div class="row mb-3">
            <div class="col">
              <full-calendar
                class="full-sized-calendar"
                ref="fullCalendar"
                defaultView="listWeek"
                height="auto"
                :header="headerOptions"
                :plugins="calendarPlugins"
                :weekends="true"
                :events="calendarEvents"
                :allDayText="'All Day'"
                :selectable="true"
                :event-time-format="eventTimeFormat"
                :first-day="1"
                :next-day-threshold="'09:00:00'"
                @eventRender="eventRender"
              ></full-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import listPlugin from "@fullcalendar/list";
require("@fullcalendar/core/locales/en-gb.js");
require("@fullcalendar/core/main.css");
require("@fullcalendar/list/main.css");
export default {
  props: ["clientObj"],
  data() {
    return {
      client: null,
      loading: true,
      diary: [],
      calendarPlugins: [listPlugin],
      calendarEvents: [],
      eventTimeFormat: {
        // like '14:30:00'
        hour: "2-digit",
        minute: "2-digit",
        meridiem: false,
        hour12: false,
      },
      headerOptions: {
        left: "prev,next",
        center: "title",
        right: "today",
      },
    };
  },
  methods: {
    fetchDiary() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/health-diary"
        )
        .then(({ data }) => {
          this.diary = data.diary;
          this.calendarEvents = data.calendar.map((obj) => ({
            ...obj,
            date: moment.utc(obj.date).local().format("YYYY-MM-DD HH:mm:ss"),
          }));
          this.$EventBus.$emit("refreshClient");
          this.loading = false;
        });
    },
    eventRender(event, element) {
      let item = this.calendarEvents.find((item) => {
        return item.id == event.event.id;
      });

      if (item.linked_feeling) {
        event.el.innerHTML = event.el.innerHTML.replace(
          "</a>",
          "</a><p class='mb-0'>I felt " + item.linked_feeling + "</p>"
        );
      }
    },
    async fetchClientData() {

      if(this.clientObj) {
        this.client = this.clientObj;
        return;
      }

      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.clientObj ? this.clientObj.id : this.$route.params.id;
    await this.fetchClientData();
    this.fetchDiary();
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  components: { FullCalendar },
};
</script>

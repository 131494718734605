<template>
  <div
    class="modal fade"
    id="previewMymopModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="expandedTaskModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content" v-if="previewTemplate">
        <div class="modal-header">
          <h5
            class="modal-title client-tab-heading"
            id="expandedTaskModalTitle"
          >
            {{ previewTemplate.title }} Preview
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-for="question in previewTemplate.questions">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <p>{{ question.question }}</p>
                  <vue-slider
                    class="mb-5 mx-2"
                    :min="0"
                    :max="10"
                    :interval="1"
                    :marks="true"
                  />
                  <p v-if="question.additional_text">
                    {{ question.additional_text }}
                  </p>
                  <textarea
                    v-if="question.enable_comments"
                    class="form-control"
                    placeholder="Add a comment (max 500 characters)"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          >
            Close Preview
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
  props: ["previewTemplate"],
  data() {
    return {};
  },
  methods: {},
  mounted() {
    $("#previewMymopModal").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });
  },
  filters: {},
  watch: {},
  computed: {},
  components: {
    VueSlider,
  },
};
</script>

<style>
</style>

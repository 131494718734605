<template>
  <div>
    <div class="row mt-4">
      <div class="col">
        <div class="card bg-light">
          <div class="card-body">
            <busy :visible="loading" />
            <div v-if="!loading">
              <div class="row mb-3">
                <div class="col">
                  <p class="alert alert-primary">
                    <b>Step 1:</b> Choose a Health Tracker template from the list below.
                    You can edit and preview the template before sharing with
                    your client. You can build your own templates in the
                    <a href="/settings/tracker">Settings Area</a>.
                  </p>
                  <label>Health Tracker Template</label>
                  <div class="row">
                    <div class="col pe-0">
                      <select v-model="selectedTemplate" class="form-control">
                        <option :value="null" disabled selected="selected">
                          Select a template from the list
                        </option>
                        <option
                          :value="t"
                          v-for="t in templates"
                          :key="`mymop-template-id-${t.id}`"
                        >
                          {{ t.title }}
                        </option>
                      </select>
                    </div>
                    <div class="col-auto">
                      <button
                        v-if="selectedTemplate && !editTemplate"
                        class="btn btn-outline-primary me-2"
                        @click="editTemplate = true"
                      >
                        <i class="far fa-edit"></i>
                        Edit
                      </button>
                      <button
                        v-if="selectedTemplate && editTemplate"
                        class="btn btn-outline-primary me-2"
                        @click="editTemplate = false"
                      >
                        Cancel Edit
                      </button>
                      <button
                        v-if="selectedTemplate"
                        class="btn btn-outline-primary"
                        @click="showPreview"
                      >
                        <i class="far fa-eye"></i>
                        Preview
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="editTemplate">
                <div class="row mb-3">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div class="card">
                          <div class="card-body">
                            <div class="row mb-2">
                              <div class="col-10 mx-auto">
                                <div class="card bg-light">
                                  <div class="card-body">
                                    <h5>Health Tracker Builder</h5>
                                    <input
                                      type="text"
                                      class="form-control mt-1"
                                      v-model="selectedTemplate.title"
                                    />
                                    <draggable
                                      class="list-group list-group-flush"
                                      tag="ul"
                                      handle=".handle"
                                      v-model="selectedTemplate.questions"
                                      v-bind="dragOptions"
                                    >
                                      <li
                                        class="list-group-item mb-2"
                                        style="cursor: pointer !important"
                                        v-for="q in selectedTemplate.questions"
                                        :key="q.order"
                                      >
                                        <i
                                          class="fa fa-arrows-alt handle me-3"
                                          style="cursor: move"
                                        ></i>
                                        {{ q.question }}
                                        <i
                                          class="float-end far fa-trash"
                                          v-tooltip.bottom="'Delete Question'"
                                          @click.stop="deleteQuestion(q)"
                                        ></i>
                                        <i
                                          class="float-end far fa-wrench me-3"
                                          v-tooltip.bottom="'Edit Question'"
                                          @click.stop="
                                            editQuestion &&
                                            editQuestion.id === q.id
                                              ? (editQuestion = null)
                                              : (editQuestion = q)
                                          "
                                        ></i>
                                        <div
                                          class="row bg-light py-3 mt-2"
                                          v-if="
                                            editQuestion &&
                                            editQuestion.id === q.id
                                          "
                                        >
                                          <div class="col-10 mx-auto">
                                            <div class="card">
                                              <div class="card-body text-start">
                                                <label> Question </label>
                                                <input
                                                  type="text"
                                                  class="form-control mb-3"
                                                  v-model="q.question"
                                                />
                                                <label> Additional Text </label>
                                                <textarea
                                                  class="form-control mb-3"
                                                  rows="3"
                                                  maxlength="500"
                                                  v-model="q.additional_text"
                                                  placeholder="Supplementary text displayed to the client alongside the question (max 500 characters)"
                                                ></textarea>
                                                <div
                                                  class="custom-control custom-switch mb-3"
                                                  v-tooltip.bottom="
                                                    'Allows the client to enter a comment after their rating.'
                                                  "
                                                >
                                                  <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    id="enable_comments_edit"
                                                    v-model="q.enable_comments"
                                                    :checked="q.enable_comments"
                                                  />
                                                  <label
                                                    class="custom-control-label"
                                                    for="enable_comments_edit"
                                                  >
                                                    Enable Comments
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </draggable>
                                    <div class="row">
                                      <div class="col text-end">
                                        <button
                                          class="btn btn-outline-primary"
                                          v-if="
                                            !showAddQuestion &&
                                            selectedTemplate.questions.length
                                          "
                                          @click="showAddQuestion = true"
                                        >
                                          <i class="far fa-plus me-1"></i>
                                          Add Health Tracker Question
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      class="row"
                                      v-if="
                                        showAddQuestion ||
                                        !selectedTemplate.questions.length
                                      "
                                    >
                                      <div class="col-10 mx-auto">
                                        <div class="card">
                                          <div class="card-body text-start">
                                            <label> New Question </label>
                                            <input
                                              type="text"
                                              class="form-control mb-3"
                                              placeholder="Enter a question to be rated on a scale of 1-10"
                                              @keypress.enter="addQuestion"
                                              v-model="newQuestion.question"
                                            />
                                            <label> Additional Text </label>
                                            <textarea
                                              class="form-control mb-3"
                                              rows="3"
                                              maxlength="500"
                                              v-model="
                                                newQuestion.additional_text
                                              "
                                              placeholder="Supplementary text displayed to the client alongside the question (max 500 characters)"
                                            ></textarea>
                                            <div
                                              class="custom-control custom-switch mb-3"
                                              v-tooltip.bottom="
                                                'Allows the client to enter a comment after their rating.'
                                              "
                                            >
                                              <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                id="enable_comments"
                                                v-model="
                                                  newQuestion.enable_comments
                                                "
                                                :checked="
                                                  newQuestion.enable_comments
                                                "
                                              />
                                              <label
                                                class="custom-control-label"
                                                for="enable_comments"
                                              >
                                                Enable Comments
                                              </label>
                                            </div>
                                            <div class="row">
                                              <div class="col">
                                                <button
                                                  class="btn btn-light"
                                                  @click="
                                                    showAddQuestion = false
                                                  "
                                                >
                                                  <i
                                                    class="far fa-times me-1"
                                                  ></i>
                                                  Cancel
                                                </button>
                                                <button
                                                  class="btn btn-outline-primary float-end"
                                                  :disabled="
                                                    newQuestion.question
                                                      .length === 0
                                                  "
                                                  @click="addQuestion"
                                                >
                                                  <i
                                                    class="far fa-plus me-1"
                                                  ></i>
                                                  Add Health Tracker Question
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <p class="alert alert-primary">
                    <b>Step 2:</b> Choose a date for the Health Tracker plan to start,
                    and how often the client will be prompted to complete the
                    Health Tracker questions.
                  </p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <label>Start Date</label>
                  <flat-pickr
                    v-model="startDate"
                    class="form-control me-1 bg-white"
                    :config="dateConfig"
                    name="date"
                  >
                  </flat-pickr>
                </div>
                <div class="col-6">
                  <label class="d-block">Repeat every</label>
                  <input
                    type="number"
                    min="1"
                    class="form-control d-inline me-1"
                    style="width: 65px"
                    v-model="frequencyDays"
                  />
                  days
                  <i
                    class="far fa-info-circle mx-1 cursor-pointer text-primary"
                    v-tooltip.bottom="
                      'The client will be prompted to complete the Health Tracker questions every ' +
                      frequencyDays +
                      ' days'
                    "
                  >
                  </i>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <p class="alert alert-primary">
                    <b>Step 3:</b> (Optional) Choose a date for the Health Tracker plan
                    to end. You can come back and disable the plan at any time.
                  </p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <label>End Date</label>
                  <flat-pickr
                    v-model="endDate"
                    class="form-control me-1 bg-white"
                    :config="dateConfig"
                    name="date"
                  >
                  </flat-pickr>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="send_notifications"
                      v-model="sendClientNotifications"
                      :checked="sendClientNotifications"
                    />
                    <label
                      class="custom-control-label"
                      for="send_notifications"
                    >
                      Send client notifications
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col text-end">
                  <button
                    @click="cancelAddPlan"
                    class="btn btn-outline-danger me-2"
                  >
                    Cancel
                  </button>
                  <button
                    :disabled="busySending || !selectedTemplate"
                    class="btn btn-primary"
                    @click="createMymopPlan"
                  >
                    <busy v-if="busySending" button />
                    Start Health Tracker Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <preview-my-mop-modal
      v-if="selectedTemplate"
      :preview-template="selectedTemplate"
    >
    </preview-my-mop-modal>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import draggable from "vuedraggable";
import PreviewMyMopModal from "./PreviewMyMopModal";
export default {
  props: ["client", "user"],
  data() {
    return {
      loading: true,
      busySending: false,
      dateConfig: {
        dateFormat: "J F Y",
        minDate: new Date(),
      },
      templates: [],
      selectedTemplate: null,
      editTemplate: false,
      editQuestion: null,
      showAddQuestion: false,
      newQuestion: {
        question: "",
        enable_comments: false,
        additional_text: "",
      },
      sendClientNotifications: this.defaultSendClientNotifications(),
      startDate: new Date(),
      endDate: null,
      frequencyDays: 7,
    };
  },
  methods: {
    fetchTemplates() {
      this.loading = true;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/mymop/templates"
        )
        .then(({ data }) => {
          this.templates = data;
          this.loading = false;
        });
    },
    createMymopPlan() {
      this.busySending = true;
      this.frequencyDays = this.frequencyDays > 0 ? this.frequencyDays : 1;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/mymop",
          {
            template: this.selectedTemplate,
            start_date: this.startDate,
            end_date: this.endDate,
            frequency_days: this.frequencyDays,
            send_client_notifications: this.sendClientNotifications,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          if (data.state === 'success') {
            this.selectedTemplate = null;
            this.$emit("updated");
          } else {
            this.busySending = false;
          }
        });
    },
    addQuestion() {
      this.newQuestion.id = 0 - this.selectedTemplate.questions.length;
      this.selectedTemplate.questions.push(this.newQuestion);
      this.newQuestion = {
        question: "",
        enable_comments: false,
        additional_text: "",
      };
      this.showAddQuestion = false;
    },
    deleteQuestion(q) {
      this.selectedTemplate.questions = this.selectedTemplate.questions.filter(
        function (obj) {
          return obj.id !== q.id;
        }
      );
    },
    showPreview() {
      $("#previewMymopModal").modal("show");
    },
    defaultSendClientNotifications() {
      return (
        this.user.marketing_email_settings.enable_client_system_emails &&
        this.user.marketing_email_settings.enable_mymop_emails
      );
    },
    cancelAddPlan() {
      this.$emit("cancel");
    },
  },
  mounted() {
    this.fetchTemplates();

    $("#previewMymopModal").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  watch: {},
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  components: {
    PreviewMyMopModal,
    flatPickr,
  },
};
</script>

<style>
</style>

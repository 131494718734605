<template>
  <div class="container">
    <div v-if="client">
      <div class="row mb-0">
        <div class="col my-auto">
          <h5 class="mb-0 client-tab-heading">Forms</h5>
        </div>
      </div>

      <!--  -->
      <hr class="my-3" />

      <busy :visible="busy" />
      <div v-if="!busy" class="formsView">
        <share-form-with-client
          :requests="requests"
          :client="client"
          @refresh="fetchRequests"
        />

        <forms-list
          v-if="requests.length > 0"
          :requests="requests"
          :client="client"
          @openClientRequest="openClientRequest"
          @openPractitionerRequest="openPractitionerRequest"
          @refresh="fetchRequests"
          class="noprint"
        />

        <div v-if="showClientResponse">
          <client-form-response-modal
            :show-response="showClientResponse"
            :videoView="videoView"
          />
          <form-response-print-container :show-response="showClientResponse" />
        </div>

        <div v-if="showPractitionerResponse">
          <practitioner-form-response-modal
            :client-form="showPractitionerResponse"
            @closed="showPractitionerResponse = null"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormResponsePrintContainer from "@/views/areas/clients/views/forms-partials/FormResponsePrintContainer";
import FormsList from "@/views/areas/clients/views/forms-partials/FormsList";
import ShareFormWithClient from "@/views/areas/clients/views/forms-partials/ShareFormWithClient";
import ClientFormResponseModal from "@/views/areas/clients/views/forms-partials/ClientFormResponseModal";
import PractitionerFormResponseModal from "@/views/areas/clients/views/forms-partials/PractitionerFormResponseModal";
require("jquery-ui-bundle");
require("formBuilder/dist/form-render.min.js");

export default {
  props: ["videoView", "clientObj"],
  data() {
    return {
      client: null,
      busy: true,
      requests: [],
      showClientResponse: null,
      showPractitionerResponse: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    async fetchRequests() {
      try {
        const response = await this.$axios.get(
          process.env.VUE_APP_API_URL +
            `/clients/api/${this.$route.params.id}/form-requests`
        );

        this.requests = response.data;
        this.busy = false;
      } catch (e) {
        console.error(`There was a problem fetching requests: ${e}`);
      }
    },
    openClientRequest(request) {
      this.showClientResponse = request;

      for (let i = 0; i < this.showClientResponse.sections.length; i++) {
        const section = this.showClientResponse.sections[i];

        setTimeout(() => {
          var formRenderOptions = {
            dataType: "json",
            formData: section.client_response,
            layoutTemplates: {
              default: function (field, label, help, data) {
                if (data.type === "radio-group") {
                  const selectedVal = data.userData ? data.userData : "";

                  help = "<p>";
                  data.values.forEach((val) => {
                    console.log(selectedVal);
                    console.log(val);
                    if (selectedVal == val.value) {
                      help +=
                        "<i class='far fa-check-circle text-primary me-1'></i>" +
                        "<u class='me-3'>" +
                        val.label +
                        "</u><br />";
                    } else {
                      help +=
                        "<i class='far fa-circle text-light me-1'></i>" +
                        "<span class='me-3'>" +
                        val.label +
                        "</span><br />";
                    }
                  });
                  help += "</p>";

                  $(field).addClass("d-none");
                }

                if (data.type === "checkbox-group") {
                  help = "<p>";
                  data.values.forEach((val) => {
                    if (data.userData && data.userData.includes(val.value)) {
                      help +=
                        "<i class='far fa-check-circle text-primary me-1'></i>" +
                        "<u class='me-3'><b>" +
                        val.label +
                        "</b></u><br />";
                    } else {
                      help +=
                        "<i class='far fa-circle text-light me-1'></i>" +
                        "<span class='me-3'>" +
                        val.label +
                        "</span><br />";
                    }
                  });
                  help += "</p>";

                  $(field).addClass("d-none");
                }

                if (
                  field.type === "textarea" ||
                  field.type === "text" ||
                  field.type === "date" ||
                  field.type === "email" ||
                  field.type === "number"
                ) {
                  help =
                    "<p><br /><div class='rounded ms-1 p-3 d-block' " +
                    "style='border: 1px solid #B3BEBF'>" +
                    data.userData
                      ? data.userData
                      : "" + "</div></p>";
                  $(field).addClass("d-none");
                }

                if (field.type === "select-one") {
                  const value = data.userData ? data.userData[0] : "";
                  help =
                    "<div class='rounded px-3 py-2' " +
                    "style='border: 1px solid #B3BEBF'>" +
                    value +
                    "</div>";
                  $(field).addClass("d-none");
                }

                return $("<div/>").append(label, field, help);
              },
            },
          };

          $(".form-render-" + section.id).formRender(formRenderOptions);

          $(".client-response-body")
            .find("input, textarea, select")
            .attr("disabled", "disabled");

          $("#form-responses").modal("show");
        }, 200);
      }
    },
    openPractitionerRequest(request) {
      this.showPractitionerResponse = request;
      $("#practitioner-form-response-modal").modal("show");
    },
    async fetchClientData() {

      if(this.clientObj) {
        this.client = this.clientObj;
        return;
      }

      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async created() {
    this.$stash.clientInView = this.clientObj ? this.clientObj.id : this.$route.params.id;
    await this.fetchClientData();
    this.fetchRequests();
  },
  components: {
    PractitionerFormResponseModal,
    ClientFormResponseModal,
    ShareFormWithClient,
    FormsList,
    FormResponsePrintContainer,
  },
};
</script>

<style lang="scss" scoped>
.table-hover .rows {
  cursor: pointer;
}

.formbuilder-textarea-label {
  display: block !important;
}

@media print {
  #form-responses {
    display: block;
    background-color: white;
    opacity: 1 !important;
  }

  .collapse {
    display: block !important;
    height: auto !important;
  }

  .modal {
    height: 100% !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: 100% !important;
  }

  .card-body {
    padding: 0px 40px;
  }

  h2 > button {
    font-size: 28px;
  }

  .modal-backdrop {
    display: none;
  }
}
</style>

<template>
  <div
    id="form-print-container"
    class="show-when-printing printit bg-white"
    v-if="showResponse"
  >
    <div class="row mb-4">
      <div class="col my-auto">
        <p class="lead font-xl">{{ showResponse.title }}</p>
        <p class="mt-3 font-l">{{ showResponse.client.name }}</p>
        <p class="font-l" v-if="showResponse.client_response_date">
          {{ showResponse.client_response_date | formatDateAlt }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row mb-3" v-for="section in showResponse.sections" :key="section.id">
          <div class="col">
            <div class="card shadow-none w-100" style="border-radius: 0">
              <div class="card-header" :id="'heading' + section.id">
                <p>
                    <span class="fw-bold font-l">{{ section.title }}</span>
                    <span
                      class="float-end font-weight-normal"
                      v-if="section.scored"
                    >
                      Score: <b>{{ section.score }}</b>
                    </span>
                </p>
              </div>
            </div>
            <div class="card shadow-none">
              <div class="card-body p-4" :ref="'body-' + section.id">
                <form
                  :id="'form-render-' + section.id"
                  :class="'form-render-' + section.id"
                ></form>
              </div>
            </div>
          </div>

          <div
            v-if="formResponseHasScoredSections(showResponse)"
            style="border-radius: 0"
          >
            <div class="card shadow-none w-100" style="border-radius: 0">
              <div class="card-header ps-1 pe-3" :id="'heading-total'">
                <h4 class="ms-3">
                  Scored Grand Total: {{ calculateScoredTotal(showResponse) }}
                </h4>
              </div>
            </div>
            <div class="card shadow-none"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showResponse"],
  data() {
    return {};
  },
  methods: {
    formResponseHasScoredSections(response) {
      if (response.sections) {
        return (
          response.sections.filter((item) => {
            return item.scored;
          }).length > 0
        );
      } else {
        return false;
      }
    },
    calculateScoredTotal(response) {
      if (response.sections) {
        return response.sections.reduce((a, b) => +a + +b.score, 0);
      } else {
        return false;
      }
    },
  },
  filters: {
    formatDateAlt(date) {
      return moment(date).format("LL");
    },
  },
};
</script>

<style scoped>
.font-l {
  font-size: 1.4rem;
}
.font-xl {
  font-size: 1.6rem;
}

@page {
  size: A4;
  margin: 2cm 1.6cm 1.6cm 1.6cm;
}

@media print {
  #form-print-container {
    position: static;
    z-index: 9999;
    top: 10px;
    left: 10px;
  }
}
</style>

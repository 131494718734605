<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="custom-control custom-switch">
          <toggle-button v-model="consultationForm.confirmed"
                         :sync="true"
                         class="my-auto"
                         :color="'#03006b'" />
          <label class="custom-control-label ms-1">
            Confirmed with Client
            <i class="far fa-info-circle text-info ms-1"
               v-tooltip:bottom="'Unconfirmed appointments are placeholders which are not yet confirmed with the client. You can set up an email schedule below and confirm with the client at a later date.'"
            ></i>
          </label>
        </div>
      </div>
    </div>
    <div v-if="!consultationForm.confirmed">
      <div class="row mt-3" :class="consultationForm.unconfirmed_email_method !== 'none' ? 'mb-3' : ''">
        <div class="col-6">
          <label>Email Schedule</label>
          <select v-model="consultationForm.unconfirmed_email_method"
                  class="form-control"
                  required>
            <option value="none" selected>Do not send emails</option>
            <option value="rolling">Rolling Frequency</option>
            <option value="custom">Custom Schedule</option>
          </select>
        </div>
      </div>

      <div v-if="consultationForm.unconfirmed_email_method === 'rolling'">
        <div class="row mb-3">
          <div class="col-6">
            <label>Email Template</label>
            <select class="form-control"
                    v-model="consultationForm.unconfirmed_email_template"
                    required>
              <option :value="null" selected disabled>Select an Email Template</option>
              <option v-for="template in emailTemplates"
                      :value="template.id">{{template.title}}</option>
            </select>
          </div>
          <div class="col-3">
            <label>Frequency</label>
            <select class="form-control"
                    v-model="consultationForm.unconfirmed_email_frequency"
                    required>
              <option value="3">Every 3 days</option>
              <option value="5">Every 5 days</option>
              <option value="7">Every 7 days</option>
              <option value="10">Every 10 days</option>
            </select>
          </div>
          <div class="col-3">
            <label>End Date</label>
            <flat-pickr
                v-model="consultationForm.unconfirmed_email_end_date"
                class="form-control me-1 bg-white"
                placeholder="End Date"
                :config="dateConfig"
                name="date"
                required
            >
            </flat-pickr>
          </div>
        </div>
      </div>

      <div v-if="consultationForm.unconfirmed_email_method === 'custom'">
        <div class="row mb-3" v-for="row in consultationForm.custom_email_schedule">
          <div class="col-6">
            <select class="form-control"
                    v-model="row.email_template_id"
                    required>
              <option :value="undefined" selected disabled>Select an Email Template</option>
              <option v-for="template in emailTemplates"
                      :value="template.id">{{template.title}}</option>
            </select>
          </div>
          <div class="col-5 me-0">
            <flat-pickr
                v-model="row.send_date"
                class="form-control me-1 bg-white"
                placeholder="Pick a date/time to send"
                :config="dateConfig"
                name="date"
                required
            >
            </flat-pickr>
          </div>
          <div class="col-1 pt-2 ms-0 me-0 text-start">
            <p>
              <i class="far fa-trash cursor-pointer" @click="removeRow(row)"></i>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-outline-primary btn-sm" @click="addRow">
              <i class="far fa-plus me-1"></i>
              Add Row
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";

export default {
  props: ["value"],
  data() {
    return {
      consultationForm: this.value,
      emailTemplates: []
    };
  },
  methods: {
    addRow() {
      this.custom_email_schedule.push({});
    },
    removeRow(row) {
      this.custom_email_schedule = this.custom_email_schedule.filter(function (filterRow) {
        return filterRow !== row;
      });
    },
    fetchEmailTemplates() {
      this.$axios
          .get(process.env.VUE_APP_API_URL + "/email-templates/api")
          .then(({ data }) => {
            this.emailTemplates = data.filter(function (template) {
              return template.user_id > 0;
            });
          });
    },
  },
  computed: {
    dateConfig() {
      return {
        altFormat: 'J F Y H:i',
        altInput: true,
        dateFormat: 'Y-m-d H:i',
        enableTime: true,
        allowInput: true,
        plugins: [
          ShortcutButtonsPlugin({
            button: {
              class: 'btn form-control',
              label: 'Clear',
            },
            onClick: (index, fp) => {
              fp.clear();
              fp.close();
            }
          })
        ]
      };
    },
  },
  created() {
    this.fetchEmailTemplates();
    this.consultationForm.unconfirmed_email_end_date = this.consultationForm.start;
  },
  filters: {
  },
  components: {
    flatPickr
  },
  watch: {
    consultationForm: {
      handler: function(val) {
        this.$emit("input", this.consultationForm);
      },
      deep: true
    },
  }
};
</script>

<style>
</style>
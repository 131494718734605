<template>
  <div
    class="modal fade show mx-auto noprint"
    id="form-responses"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myExtraLargeModalLabel"
  >
    <div
      class="modal-dialog modal-lg noprint"
      :class="videoView === true ? 'video-mode' : ''"
      role="document"
    >
      <div class="modal-content" v-if="showOptions">
        <div class="modal-header">
          <h4>Advanced Options</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-header bg-primary text-white">Sections</div>
            <ul class="list-group list-group-flush">
              <li
                v-for="(section, index) in showResponse.sections"
                class="list-group-item"
              >
                <div class="row">
                  <div class="col-9">{{ section.title }}</div>
                  <div class="col-3">
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="'switch' + index"
                        v-model="AdvancedOptions.SectionsToHide[index]"
                      />
                      <label
                        class="custom-control-label"
                        :for="'switch' + index"
                        >Hide Section</label
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-sm btn-outline-primary"
            @click="showOptions = false"
          >
            Back
          </button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <div class="modal-header">
          <h5
            class="modal-title client-tab-heading"
            id="myExtraLargeModalLabel"
          >
            Client Response
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body client-response-body">
          <div class="row">
            <div class="col">
              <div v-if="showResponse">
                <div class="row">
                  <div class="col my-auto">
                    <h5 class="mb-2">{{ showResponse.title }}</h5>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col">
                    <div class="accordion" id="sectionsAccordion">
                      <div
                        v-for="(section, index) in showResponse.sections"
                        class="shadow"
                      >
                        <div
                          class="card shadow-none"
                          v-if="!AdvancedOptions.SectionsToHide[index]"
                        >
                          <div
                            class="card-header ps-1 pe-3"
                            :id="'heading' + section.id"
                          >
                            <h2 class="mb-0">
                              <button
                                class="
                                  btn btn-link
                                  fw-bold
                                  w-100
                                  text-start
                                "
                                type="button"
                                data-toggle="collapse"
                                :data-target="'#collapse' + section.id"
                                aria-expanded="false"
                                :aria-controls="'collapse' + section.id"
                              >
                                <i class="far fa-caret-down me-2"></i>
                                {{ section.title }}
                                <span
                                  class="float-end font-weight-normal"
                                  v-if="section.scored"
                                >
                                  Score: <b>{{ section.score }}</b>
                                </span>
                              </button>
                            </h2>
                          </div>
                        </div>
                        <div class="card">
                          <div
                            :id="'collapse' + section.id"
                            class="collapse"
                            :aria-labelledby="'heading' + section.id"
                            data-parent="#sectionsAccordion"
                          >
                            <div class="card-body" :ref="'body-' + section.id">
                              <form
                                :id="'form-render-' + section.id"
                                :class="'form-render-' + section.id"
                              ></form>

                              <div v-if="section.scored">
                                <h5 class="fw-bold">
                                  Section Score Total: {{ section.score }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="formResponseHasScoredSections(showResponse)"
                        style="border-radius: 0"
                      >
                        <div
                          class="card shadow-none w-100"
                          style="border-radius: 0"
                        >
                          <div
                            class="card-header ps-1 pe-3"
                            :id="'heading-total'"
                          >
                            <h4 class="ms-3">
                              Scored Grand Total:
                              {{ calculateScoredTotal(showResponse) }}
                            </h4>
                          </div>
                        </div>
                        <div class="card shadow-none"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-sm btn-outline-primary" @click="downloadPdf()">
            <i class="far fa-file-pdf me-1"></i>
            Download PDF
          </button>
          <button
            class="btn btn-sm btn-outline-primary"
            @click="showOptions = true"
          >
            <i class="far fa-cogs me-1"></i>
            Advanced Options
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm ms-auto"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showResponse", "videoView"],
  data() {
    return {
      showOptions: false,
      AdvancedOptions: {
        SectionsToHide: [],
      },
    };
  },
  methods: {
    downloadPdf() {
      this.$nextTick(() => {
        const element = window.document.getElementById("form-print-container");

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }

        const win = window.open("about:blank", "_blank");

        win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Form Preview</title>
          </head>
          <body class="bg-white">
            <div class="ms-2 p-2 container">
            ${element.innerHTML}
            </div>
          </body>
        </html>
      `);
        setTimeout(() => {
          win.focus();
          win.print();
          win.close();
        }, 200);
      });
    },
    formResponseHasScoredSections(response) {
      if (response.sections) {
        let scoredResponses = response.sections.filter((item) => {
          return item.scored;
        });
        return scoredResponses && scoredResponses.length > 0;
      } else {
        return false;
      }
    },
    calculateScoredTotal(response) {
      if (response.sections) {
        return response.sections.reduce((a, b) => +a + +b.score, 0);
      } else {
        return false;
      }
    },
    initialiseOptions() {
      for (let i = 0; i < this.showResponse.sectionsthis.length; i++) {
        this.AdvancedOptions.SectionsToHide[i] = false;
      }
    },
  },
  mounted() {
    this.initialiseOptions();
  },
};
</script>

<style lang="scss" scoped>
.video-mode {
  position: absolute !important;
  left: 5%;
  z-index: 10000;
  width: 40%;
}

.table-hover .rows {
  cursor: pointer;
}

.formbuilder-textarea-label {
  display: block !important;
}

@media print {
  #form-responses {
    display: block;
    background-color: white;
    opacity: 1 !important;
  }

  .collapse {
    display: block !important;
    height: auto !important;
  }

  .modal {
    height: 100% !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: 100% !important;
  }

  .card-body {
    padding: 0px 40px;
  }

  h2 > button {
    font-size: 28px;
  }

  .modal-backdrop {
    display: none;
  }
}
</style>

<template>
  <div class="container-fluid">
    <div
      v-if="!loading && !$stash.loadedTemplate"
      class="row"
    >
      <div class="col-md-6 mx-auto">
        <create-template></create-template>
      </div>
    </div>
    <busy v-else-if="loading"/>
    <div
      v-if="$stash.loadedTemplate"
      class="row HR-VIEW-CLASS-REF-FOR-PDF-GENERATOR"
    >
      <div class='col-md-11 w-100 page-area-print-wide'>
        <div>
          <div class="card border-0 bg-dark mb-3" v-if="client">
            <div class="card-body text-white px-3 py-2">
              <p class="mb-0 text-center">
                {{ client.name }} - {{ $stash.loadedTemplate.title }}
              </p>
            </div>
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            v-if="client.has_allergies"
          >
            <div class="row">
              <div class="col-3">
                <b><i class="fas fa-exclamation-triangle"></i></b>
              </div>
              <div class="col-9">
                <p>
                  <small>
                    Client has allergies & intolerances
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style="max-height: 70vh; overflow-y: auto;">
        <form-area
          :client="client"
          :history="history"
        ></form-area>
        </div>
      </div>
      <div class="col-md-1 noprint">
          <form-builder-toolbar :client="client" :template="template" :resources="resources" :miniview="true"/>
          <form-builder-items
              class="mb-3"
              :client="client"
              :template="template"
              :resources="resources"
              :miniview="true"
            ></form-builder-items>
          <div
            class="alert alert-danger d-block btn w-50 mt-3"
            role="alert"
            v-if="client.has_allergies"
          >
            <b><i class="fas fa-exclamation-triangle" v-tooltip.left="'Show Warnings'"></i></b>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateTemplate from "./partials/CreateTemplate";
import FormArea from "./partials/FormArea";
import FormBuilderItems from "./partials/FormBuilderItems";
import FormBuilderToolbar from "./partials/FormBuilderToolbar";

export default {
  props: ['inputView', 'client', 'templateId'],
  data() {
    return {
      loading: true,
      template: null,
      unsavedChanges: false,
      resources: [],
      sidebar: true,
      history: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    fetchClientTemplate() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/documents/" +
            this.client.id +
            "/" +
            this.templateId
        )
        .then(({ data }) => {
          this.template = data.template;
          this.client = data.client;

          this.checkLoaded();
          if (this.template.resources) {
            this.resources = JSON.parse(this.template.resources);
          }
        });
    },
    updateTemplate(silent) {
      this.$axios
        .post(
          this.inputView
            ? process.env.VUE_APP_API_URL +
                "/documents/client-version/" +
                this.templateId
            : process.env.VUE_APP_API_URL +
                "/documents/" +
                this.templateId,
          {
            data: this.$stash.templateItems,
            resources: this.resources,
          }
        )
        .then(({ data }) => {
          this.$stash.loadedTemplate = data.template;
          this.$stash.unsavedChanges = false;
          if (!silent) {
            this.$EventBus.$emit("alert", data);
          }
          this.addHistory(data.template);
        });
    },
    checkLoaded() {
      if (this.template != null) {
        this.$stash.loadedTemplate = this.template;
        this.$stash.templateItems = this.$stash.loadedTemplate.data;
        this.loading = false;
      }
    },
    clearStore() {
      this.template = null;
      this.$stash.loadedTemplate = null;
      this.$stash.templateItems = [];
      this.$stash.currentSelectedRow = null;
      this.$stash.currentSelectedCol = null;
      this.$stash.currentSelectedColKey = null;
      this.$stash.currentSelectedItem = null;
    },
    addHistory(template) {
      let templateData = JSON.stringify(template.data);
      if (this.history[this.history.length - 1] !== templateData) {
        this.history.push(templateData);
      }
    },
    historyRollback() {
      if (this.history.length > 0) {
        this.history.splice(this.history.length - 1);
        this.$stash.templateItems = JSON.parse(
          this.history[this.history.length - 1]
        );
      }
    },
  },
  created() {
    this.clearStore();
    this.fetchClientTemplate();
    window.addEventListener("beforeunload", (event) => {
      if (this.$stash.unsavedChanges) {
        event.returnValue = `There are unsaved changes, are you sure you want to leave?`;
      }
    });
  },
  beforeDestroy() {
    this.clearStore();
  },
  mounted() {
    this.$EventBus.$on("saveTemplate", (silent = true) => {
      this.updateTemplate(silent);
    });
    this.$EventBus.$on("addResource", (resource) => {
      this.resources.push(resource);
      this.updateTemplate(true);
    });
    this.$EventBus.$on("removeResource", (resource) => {
      this.resources.splice(this.resources.findIndex(r => r.id === resource.id) , 1);
      this.updateTemplate(true);
    });
    this.$EventBus.$on("undo", () => {
      this.historyRollback();
    });
  },
  components: {
    CreateTemplate,
    FormArea,
    FormBuilderItems,
    FormBuilderToolbar,
  },
};
</script>

<style scoped>
.sticky-top {
  top: 1rem;
}

.warning {
  position: fixed;
  bottom: 0;
  right: 0;
  color: #ff0000;
}

.sidebar-tools {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<template>
  <div>
    <div class="row my-3">
      <div class="col text-end">
        <div class="dropdown d-inline mx-1">
          <button
            class="btn btn-outline-dark btn-sm dropdown-toggle"
            type="button"
            id="sortByDropDown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Sort by
          </button>
          <div class="dropdown-menu" aria-labelledby="sortByDropDown">
            <a
              class="dropdown-item"
              @click="sortRequestsBy('oldest-newest')"
              >Oldest to Newest</a
            >
            <a
              class="dropdown-item"
              @click="sortRequestsBy('newset-oldest')"
              >Newest to Oldest</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
          <div class="col">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Form</th>
                  <th scope="col">Request Sent</th>
                  <th scope="col">Response Received</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="r in requests"
                  :key="r.id"
                  class="cursor-pointer"
                >
                  <td @click="openFormModal(r)">
                    {{ r.title }}
                    <br />
                    <small>{{r.category}}</small>
                  </td>
                  <td @click="openFormModal(r)">
                    <span v-if="r.completed_by === 'client'">{{ r.created_at | formatDate }}</span>
                    <span v-else>N/A - Practitioner Form</span>
                  </td>
                  <td @click="openFormModal(r)">

                    <span v-if="r.completed_by === 'client'">
                      <span v-if="r.client_response_date">
                        {{ r.client_response_date | formatDate }}
                        <br />
                        <small>Completed by client</small>
                      </span>
                      <span v-else>
                        <i class="fa fa-times-circle text-danger me-1"></i><i><small>awaiting response</small></i>
                      </span>
                    </span>
                    <span v-else>
                      {{ r.updated_at | formatDate }}
                      <br />
                      <small>Completed by practitioner</small>
                    </span>


                  </td>
                  <td class="text-end text-nowrap">
                    <div class="dropdown">
                      <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div v-if="r.completed_by === 'client'">
                          <a
                              v-if="r.client_response_date"
                              class="dropdown-item"
                              @click.stop="reOpenRequest(r.id)"
                              v-tooltip.bottom="
                          'Reopens the form, allowing the client to modify their response.'
                        "
                          >
                            <i class="far fa-sync fa-fw"></i>
                            Reopen Request
                          </a>
                          <a v-if="!r.client_response_date" class="dropdown-item" @click.stop="reSendRequest(r)">
                            <i class="far fa-sync fa-fw"></i>
                            Resend Request
                          </a>
                          <a v-if="!r.client_response_date" class="dropdown-item" @click.stop="cancelRequest(r)">
                            <i class="far fa-times fa-fw text-danger"></i>
                            Cancel Request
                          </a>
                        </div>
                        <div v-else>
                          <a v-if="!r.client_response_date" class="dropdown-item" @click.stop="openFormModal(r)">
                            <i class="far fa-edit fa-fw"></i>
                            Fill in Response
                          </a>
                          <a v-if="!r.client_response_date" class="dropdown-item" @click.stop="cancelRequest(r)">
                            <i class="far fa-times fa-fw text-danger"></i>
                            Cancel Request
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  props: ["requests", "client"],
  data() {
    return {
    };
  },
  methods: {
    openFormModal(request) {
      if (request.completed_by === 'client') {
        this.$emit("openClientRequest", request);
      } else {
        this.$emit("openPractitionerRequest", request);
      }
    },
    sortRequestsBy(sort) {
      if (sort == "oldest-newest") {
        this.requests.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (sort == "newset-oldest") {
        this.requests.sort(
          (b, a) => new Date(a.created_at) - new Date(b.created_at)
        );
      }
    },
    reOpenRequest(requestId) {
      var confirmed = confirm(
        "Are you sure you wish to re-open this form request?"
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client +
              "/forms/reopen",
            {
              form_request_id: requestId,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$emit("refresh");
          });
      }
    },
    reSendRequest(r) {
      var confirmed = confirm(
          "Are you sure you wish to re-send this form request?"
      );
      if (confirmed) {
        this.$axios
            .post(
                process.env.VUE_APP_API_URL +
                "/clients/api/" +
                this.client.id +
                "/forms/resend",
                {
                  form_template_id: r.form_template_id,
                }
            )
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.$emit("refresh");
            });
      }
    },
    cancelRequest(r) {
      var confirmed = confirm(
          "Are you sure you wish to cancel this form request? Your client will no longer be able to complete this form unless it is re-issued."
      );
      if (confirmed) {
        this.$axios
            .post(
                process.env.VUE_APP_API_URL +
                "/clients/api/" +
                this.client.id +
                "/forms/delete",
                {
                  client_form_id: r.id,
                }
            )
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.$emit("refresh");
            });
      }
    },
  },
  created() {
  },
  mounted() {},
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateAlt(date) {
      return moment(date).format("LL");
    },
  },
  components: {
  },
};
</script>

<style lang="scss" scoped>
.table-hover .rows {
  cursor: pointer;
}

.formbuilder-textarea-label {
  display: block !important;
}

@media print {
  #form-responses {
    display: block;
    background-color: white;
    opacity: 1 !important;
  }

  .collapse {
    display: block !important;
    height: auto !important;
  }

  .modal {
    height: 100% !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: 100% !important;
  }

  .card-body {
    padding: 0px 40px;
  }

  h2 > button {
    font-size: 28px;
  }

  .modal-backdrop {
    display: none;
  }
}
</style>

<script>
import { Bar, mixins } from 'vue-chartjs'
export default {
  extends: Bar,
  props: ["data", "comments"],
  data() {
    return {

    };
  },
  methods: {
  },
  mounted () {
    // Overwriting base render method with actual data.
    let options = {
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    max: 10,
                    min: 0,
                    stepSize: 1
                }
            }]
        },
    };

    this.renderChart(this.data, options);
  },
  filters: {
  },
  watch: {
  },
  computed: {

  },
  components: {
      Bar
  }
};
</script>

<style>
</style>

<template>
  <div>
    <mymop-tab :user="user" :clientObj="client"></mymop-tab>
  </div>
</template>

<script>
import MymopTab from "../../clients/views/HealthTracker";

export default {
  props: ["client", "user"],
  filters: {
    dateFormat(date) {
      return moment.utc(date).format("LLL");
    },
  },
  methods: {
    close() {
      this.$emit("closed");
    },
  },
  components: {
    MymopTab,
  },
};
</script>

<style>
</style>

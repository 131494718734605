<template>
  <div class="folder-card mb-2 rounded" :id="'folder-card-' + this.folder.id">
    <div class="cursor-pointer">
      <div @click="openFolder(folder)"
           draggable="true"
           class="pt-2 rounded"
           @dragstart="folderDragStart">
        <i class="far fa-folder fa-4x text-info"
           @dragover.prevent
           @drop.stop="folderDrop($event)"
           @dragenter.stop="folderDragEnter($event)"
           @dragleave.stop="folderDragLeave($event)"></i>
        <p v-if="!editThisFolder" class="mt-1">{{ folder.name }}</p>
        <input type="text" class="form-control my-2" v-if="editThisFolder" :value="folder.name"
          @change="renameFolder(folder, $event)" @click.stop autofocus/>
      </div>
    </div>
    <div class="row text-center">
      <span class="mx-auto">
          <i
            class="far fa-edit text-secondary cursor-pointer"
            v-tooltip.bottom="'Rename Folder'"
            @click="editThisFolder=!editThisFolder"
          ></i>

          <!--<i
          class="far fa-share-square text-primary cursor-pointer mx-1"
          v-if="!folder.share"
          v-tooltip.bottom="'Share folder and files to client portal'"
          @click.stop="shareFolder(folder)"
        ></i>-->

          <i class="far fa-trash text-danger cursor-pointer"
             v-tooltip.bottom="'Delete Folder'"
             @click="deleteFolder(folder)"></i>
        </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["folder"],
  data() {
    return {
      editThisFolder: false
    };
  },
  methods: {
    shareFolder(folder) {
      this.$emit("shareFolder", folder);
    },
    deleteFolder(folder) {
      this.$emit("deleteFolder", folder);
    },
    renameFolder(folder, event) {
      this.$emit("renameFolder", {folder: folder, name: event.target.value})
      this.editThisFolder = false;
    },
    openFolder(folder) {
      this.$emit("openFolder", folder);
    },
    folderDragStart(event) {
      console.log(this.folder);
      event.dataTransfer.setData('text/plain', JSON.stringify({
        type: "folder",
        id: this.folder.id,
        parent_id: this.folder.folder ? this.folder.folder.folder_id : null,
      }));
    },
    folderDragEnter(event){
      $('#folder-card-' + this.folder.id).addClass('bg-primary');
    },
    folderDragLeave(event){
      $('#folder-card-' + this.folder.id).removeClass('bg-primary');
    },
    folderDrop(event){
      $('#folder-card-' + this.folder.id).removeClass('bg-primary');
      const evtData = JSON.parse(event.dataTransfer.getData('text'));
      console.log ("Move " + evtData.type + " #" + evtData.id + " to folder " + this.folder.name)
      if (this.folder.id !== evtData.id){
        const emitData = {
          target_folder_id: this.folder.id,
          source_type: evtData.type,
          source_id: evtData.id,
        }
        this.$axios.post(process.env.VUE_APP_API_URL + "/clients/api/" + this.client + "/files/move", emitData)
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.$emit("refreshed");
            });
      }
    },
  },
  computed: {
    toolTipText(){
      var ret = "";
      if (this.file.uploaded_by_client){ ret = ret + "Uploaded by client <br />"}
      ret = ret + "Uploaded " + moment(this.file.created_at).format("L") + "<br />";
      if (!this.file.uploaded_by_client && this.file.share){ ret = ret + "Shared " + moment(this.file.share.created_at).format("L") + "<br />"}
      return ret;
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    }
  },
  mounted() {
  },
  components: {
  }
};
</script>

<style>
  .folder-card:hover{
    background-color: #f4f6f6 !important;
  }
</style>

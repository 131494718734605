<template>
  <div>
    <diary-tab :user="user" :clientObj="client"></diary-tab>
  </div>
</template>

<script>
import DiaryTab from "../../clients/views/Diary";

export default {
  props: ["client", "user"],
  filters: {
    dateFormat(date) {
      return moment.utc(date).format("LLL");
    },
  },
  methods: {
    close() {
      this.$emit("closed");
    },
  },
  components: {
    DiaryTab,
  },
};
</script>

<style>
</style>

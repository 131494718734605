<template>
  <div>
    <div v-if="page === 1">
      <service-or-package v-model="consultationForm" />

      <div class="row mt-3">
        <div class="col text-end">
          <button class="btn btn-primary"
                  type="button"
                  @click.prevent="page=2"
                  :disabled="!(consultationForm.practitioner_service || consultationForm.package)">
            <i class="far fa-arrow-right me-1" />
            Next
          </button>
        </div>
      </div>
    </div>

    <form v-else-if="page===2" @submit.prevent="bookConsultation">
      <h5 class="text-primary" v-if="consultationForm.booking_type === 'service'">
        <b>{{ consultationForm.service.name }}</b> with <b>{{consultationForm.practitioner_service.user.name}}</b>
      </h5>
      <h5 class="fw-bold text-primary" v-else-if="consultationForm.booking_type === 'package'">
        {{ consultationForm.package.name }}
      </h5>

      <div v-if="consultationForm.booking_type === 'service'" class="mt-3">
        <div class="card bg-light mb-3">
          <div class="card-body">
            <appointment-date v-model="consultationForm" :pre-selected-date="preSelectedDate" />
            <delivery-method v-model="consultationForm.delivery_method" class="mt-3" />
            <video-link v-if="consultationForm.delivery_method === 'video' || consultationForm.delivery_method === 'other'"
                        v-model="consultationForm.external_link"
                        class="mt-3" />
            <service-confirmation-options v-model="consultationForm" class="mt-3"/>
          </div>
        </div>
      </div>


      <div v-if="consultationForm.booking_type === 'package'">
        <h5 class="fw-bold text-primary">Package Services</h5>
        <package-schedule v-model="consultationForm" />
      </div>

      <h5 class="fw-bold text-primary">Payment</h5>
      <div class="card bg-light mb-3">
        <div class="card-body">
          <payment v-model="consultationForm" />
        </div>
      </div>


      <h5 class="fw-bold text-primary">Communications</h5>
      <div class="card bg-light mb-3">
        <div class="card-body">
          <communications v-model="consultationForm"/>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col text-end">
          <button class="btn btn-primary" :disabled="saving">
            <i class="far fa-save me-1" />
            Book Consultation
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PackageSchedule from "../../components/booking/book-consultation-partials/PackageSchedule";
import Payment from "../../components/booking/book-consultation-partials/Payment";
import ServiceConfirmationOptions from "../../components/booking/book-consultation-partials/ServiceConfirmationOptions";
import Communications from "../../components/booking/book-consultation-partials/Communications";
import DeliveryMethod from "../../components/booking/book-consultation-partials/DeliveryMethod";
import AppointmentDate from "../../components/booking/book-consultation-partials/AppointmentDate";
import ServiceOrPackage from "../../components/booking/book-consultation-partials/ServiceOrPackage";
import VideoLink from "./book-consultation-partials/VideoLink";

export default {
  props: ["client"],
  data() {
    return {
      preSelectedDate: null,
      page: 1,
      saving: false,
      consultationForm: {
        booking_type: null,
        service: null,
        practitioner_service: null,
        package: null,
        start: null,
        duration: null,
        delivery_method: null,
        package_schedule: [],
        price: null,
        allow_instalments: null,
        instalments: [],
        confirmed: true,
        unconfirmed_email_method: "none",
        unconfirmed_email_template: null,
        unconfirmed_email_frequency: 7,
        unconfirmed_email_end_date: null,
        custom_email_schedule: [{}],
        send_emails: null,
        send_confirmation_emails: null,
        send_reminder_emails: null,
        send_follow_up_email: null,
        follow_up_email_template_id: null,
        external_link: null
      }
    };
  },
  methods: {
    checkStoreDate() {
      if(this.$stash.newEventSelectedDates) {
        this.preSelectedDate = {start: this.$stash.newEventSelectedDates.start, end: this.$stash.newEventSelectedDates.end};
      }
    },
    cancel() {
      $("#consultationBookModal").modal("hide");
    },
    bookConsultation() {
      this.saving = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/consultations/create",
            this.consultationForm
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("eventAdded", data);
          this.$emit("saved");
          $("#calendarAddEventModal").modal("hide");
          $("#consultationBookModal").modal("hide");
          this.saving = false;
        });
    },
  },
  mounted() {
    $("#consultationBookModal").modal("show");
    $("#consultationBookModal").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });

    this.checkStoreDate();

    this.$EventBus.$on("addEvent:bookConsultation", this.bookConsultation);
    this.$EventBus.$on("addEvent:cancel", this.cancel);
  },
  beforeDestroy() {
    this.$EventBus.$off("addEvent:bookConsultation", this.bookConsultation);
    this.$EventBus.$off("addEvent:cancel", this.cancel);
  },
  components: {
    VideoLink,
    ServiceOrPackage,
    AppointmentDate,
    DeliveryMethod,
    Communications,
    ServiceConfirmationOptions,
    Payment,
    PackageSchedule,
  },
};
</script>

<style>
</style>

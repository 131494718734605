<template>
  <div>
    <div class="row" :class="sendEmails ? 'mb-2' : ''">
      <div class="col">
        <toggle-button v-model="consultationForm.send_emails"
                       :sync="true"
                       class="my-auto"
                       :color="'#03006b'" />
        <label class="custom-control-label ms-1">
          Send client notifications
        </label>
      </div>
    </div>
    <div v-if="sendEmails">
      <div class="row mb-2">
        <div class="col">
          <toggle-button v-model="consultationForm.send_confirmation_emails"
                         :sync="true"
                         class="my-auto"
                         :color="'#03006b'" />
          <label class="custom-control-label ms-1">
            Appointment Confirmation Email
            <i class="far fa-info-circle text-info ms-1"
               v-tooltip:right="'Send an email to the client confirming the booking details'"
            ></i>
          </label>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <toggle-button v-model="consultationForm.send_reminder_emails"
                         :sync="true"
                         class="my-auto"
                         :color="'#03006b'" />
          <label class="custom-control-label ms-1">
            Appointment Reminder Emails
            <i class="far fa-info-circle text-info ms-1"
               v-tooltip:right="'Send reminder emails leading up to the booking'"
            ></i>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <toggle-button v-model="consultationForm.send_follow_up_email"
                         :sync="true"
                         class="my-auto"
                         :color="'#03006b'" />
          <label class="custom-control-label ms-1">
            Follow Up Email
            <i class="far fa-info-circle text-info ms-1"
               v-tooltip:right="'Send a follow up email 24 hours after the booking takes place'"
            ></i>
          </label>
          <div v-if="consultationForm.send_follow_up_email && emailTemplates.length > 0" class="mt-2">
            <select class="form-control w-50"
                    v-model="consultationForm.follow_up_email_template_id"
                    required>
              <option :value="null" selected>Select an Email Template</option>
              <option v-for="template in emailTemplates.filter(function(t) { return t.type === 'appointment'})"
                      :value="template.id">{{template.title}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      consultationForm: this.value,
      emailTemplates: [],
    };
  },
  computed: {
    sendEmails() {
      return this.consultationForm.send_emails;
    }
  },
  methods: {
    fetchEmailTemplates() {
      this.$axios
          .get(process.env.VUE_APP_API_URL + "/email-templates/api")
          .then(({ data }) => {
            this.emailTemplates = data;
          });
    },
  },
  created() {
    this.fetchEmailTemplates();
  },
  mounted() {
    if (this.consultationForm.booking_type === 'service') {
      this.consultationForm.follow_up_email_template_id = this.consultationForm.practitioner_service.service.follow_up_email_template_id;
      this.consultationForm.send_follow_up_email = this.consultationForm.practitioner_service.service.send_follow_up_email;
      this.consultationForm.send_reminder_emails = this.consultationForm.practitioner_service.service.send_reminder_email;
    } else if (this.consultationForm.booking_type === 'package') {
      this.consultationForm.send_follow_up_email = false;
      this.consultationForm.send_reminder_emails = false;
    }
    this.consultationForm.send_emails = true;
    this.consultationForm.send_confirmation_emails = true;
  },
  filters: {
  },
  components: {
  },
  watch: {
    consultationForm: {
      handler: function(val) {
        this.$emit("input", this.consultationForm);
      },
      deep: true
    },
  }
};
</script>

<style>
</style>

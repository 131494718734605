<template>
  <input class="form-control mb-3"
         placeholder="Provide an external link..."
         v-model="externalLink" />
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      externalLink: this.value,
    };
  },
  watch: {
    externalLink() {
      this.$emit("input", this.externalLink)
    }
  },
};
</script>

<style>
</style>

<template>
  <div class="container">
    <div v-if="client">
      <div id="consultationsView">
        <div class="row mb-3">
          <div class="col-auto my-auto">
            <h5 class="mb-0 client-tab-heading">Consultations</h5>
          </div>
          <div class="col text-end">
            <button
              class="btn btn-outline-primary btn-sm"
              :class="compact ? 'btn-sm' : ''"
              @click="showBooking = true"
              style="border: 1px solid #03006b !important"
            >
              <i class="far fa-user-friends me-1"></i>
              Book a Consultation
            </button>
          </div>
        </div>

        <div class="row mb-4" v-if="unconfirmedConsultations.length > 0">
          <div class="col">
            <h5 class="mb-3 fw-bold mt-3">
              Unconfirmed Consultations ({{ unconfirmedConsultations.length }})
            </h5>

            <div v-if="unconfirmedConsultations.length > 0">
              <table
                class="table table-hover table-vertical-align mb-0 bg-white"
                :class="compact ? 'table-sm' : ''"
              >
                <thead>
                  <tr>
                    <th scope="col" style="border-top: 0px">Type</th>
                    <th scope="col" style="border-top: 0px">Method</th>
                    <th scope="col" style="border-top: 0px">Appointment</th>
                    <th scope="col" style="border-top: 0px">Status</th>
                    <th scope="col" style="border-top: 0px">
                      Payment<span v-if="!compact"> Status</span>
                    </th>
                    <th scope="col" style="border-top: 0px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(c, index) in unconfirmedConsultations"
                    :key="index"
                  >
                    <td class="text-capitalize">
                      {{ c.service_name }}
                      <p class="small mb-0 pb-0">
                        {{ c.practitioner_first_name }}
                        {{ c.practitioner_last_name }}
                      </p>
                      <p class="small mb-0 pb-0">
                        {{
                          c.clinic_package_id === null
                            ? "Service"
                            : "Package: " + c.package_name
                        }}
                      </p>
                      <span v-if="c.requested_by_client" class="badge bg-info">
                        Requested by client
                      </span>
                    </td>
                    <td class="text-capitalize">
                      {{ c.method ? c.method : "TBA" | formatMethod }}
                    </td>
                    <td>
                      <span v-if="c.start">{{ c.start | formatDate }}</span>
                      <span v-else>TBA</span>
                    </td>
                    <td class="text-capitalize">
                      {{ c.status | formatStatus }}
                    </td>
                    <td class="text-capitalize">
                      {{ c.order_status | formatStatus }}
                    </td>
                    <td class="text-end">
                      <button
                        class="btn btn-outline-primary btn-sm me-2"
                        @click.stop="confirmConsultation(c)"
                      >
                        Confirm
                      </button>
                      <button
                        class="btn btn-outline-danger btn-sm"
                        @click.stop="deleteConsult(c)"
                      >
                        <i class="far fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="text-center">
              <busy :visible="loading" />
              <p v-if="!loading">No unconfirmed consultations.</p>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <h5 class="mb-3 fw-bold mt-3">
              Upcoming Consultations ({{ upcomingConsultations.length }})
            </h5>

            <div v-if="upcomingConsultations.length > 0">
              <table
                class="table table-hover table-vertical-align mb-0 bg-white"
                :class="compact ? 'table-sm' : ''"
              >
                <thead>
                  <tr>
                    <th scope="col" style="border-top: 0px">Type</th>
                    <th scope="col" style="border-top: 0px">Method</th>
                    <th scope="col" style="border-top: 0px">Appointment</th>
                    <th scope="col" style="border-top: 0px">Status</th>
                    <th scope="col" style="border-top: 0px">
                      Payment<span v-if="!compact"> Status</span>
                    </th>
                    <th scope="col" style="border-top: 0px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(c, index) in upcomingConsultations"
                    :key="index"
                    class="cursor-pointer"
                    @click="viewConsultation(c)"
                  >
                    <td class="text-capitalize">
                      {{ c.service_name }}
                      <p class="small mb-0 pb-0">
                        {{ c.practitioner_first_name }}
                        {{ c.practitioner_last_name }}
                      </p>
                      <p class="small mb-0 pb-0">
                        {{
                          c.clinic_package_id === null
                            ? "Service"
                            : "Package: " + c.package_name
                        }}
                      </p>
                    </td>
                    <td class="text-capitalize">{{ c.method | formatMethod }}</td>
                    <td v-if="c.event_start">
                      {{ c.event_start | formatDate }}
                    </td>
                    <td class="text-capitalize">
                      {{ c.status | formatStatus }}
                    </td>
                    <td class="text-capitalize">
                      {{ c.order_status | formatStatus }}
                    </td>
                    <td class="text-end">
                      <router-link
                        v-if="c.chat_room_id && c.method === 'video'"
                        class="btn btn-primary btn-sm me-2"
                        target="_blank"
                        :to="'/consultations/' + c.id"
                      >
                        <i class="far fa-video"></i>
                      </router-link>
                      <button
                        class="btn btn-outline-danger btn-sm"
                        @click.stop="deleteConsult(c)"
                      >
                        <i class="far fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="text-center">
              <busy :visible="loading" />
              <p v-if="!loading">No upcoming consultations.</p>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <h5 class="mb-3 fw-bold mt-3">
              Past Consultations ({{ pastConsultations.length }})
            </h5>

            <div v-if="pastConsultations.length > 0">
              <table
                class="table table-hover table-vertical-align mb-0 bg-white"
                :class="compact ? 'table-sm' : ''"
              >
                <thead>
                  <tr>
                    <th scope="col" style="border-top: 0px">Type</th>
                    <th scope="col" style="border-top: 0px">Method</th>
                    <th scope="col" style="border-top: 0px">Appointment</th>
                    <th scope="col" style="border-top: 0px">Status</th>
                    <th scope="col" style="border-top: 0px">
                      Payment<span v-if="!compact"> Status</span>
                    </th>
                    <th scope="col" style="border-top: 0px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(c, index) in pastConsultations"
                    :key="index"
                    class="cursor-pointer"
                    @click="viewConsultation(c)"
                  >
                    <td class="text-capitalize">
                      {{ c.service_name }}
                      <p class="small mb-0 pb-0">
                        {{ c.practitioner_first_name }}
                        {{ c.practitioner_last_name }}
                      </p>
                      <p class="small mb-0 pb-0">
                        {{
                          c.clinic_package_id === null
                            ? "Service"
                            : "Package: " + c.package_name
                        }}
                      </p>
                    </td>
                    <td class="text-capitalize">{{ c.method | formatMethod }}</td>
                    <td>
                      <span v-if="c.event_start">{{
                        c.event_start | formatDate
                      }}</span>
                    </td>
                    <td class="text-capitalize">
                      {{ c.status | formatStatus }}
                    </td>
                    <td class="text-capitalize">
                      {{ c.order_status | formatStatus }}
                    </td>
                    <td class="text-end">
                      <router-link
                        v-if="c.chat_room_id && c.method === 'video'"
                        class="btn btn-primary btn-sm me-2"
                        target="_blank"
                        :to="'/consultations/' + c.id"
                      >
                        <i class="far fa-video"></i>
                      </router-link>
                      <button
                        class="btn btn-outline-danger btn-sm"
                        @click.stop="deleteConsult(c)"
                      >
                        <i class="far fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="text-center">
              <busy :visible="loading" />
              <p v-if="!loading">No past consultations.</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <h5 class="mb-3 fw-bold mt-3">
              Cancelled Consultations ({{ cancelledConsultations.length }})
            </h5>

            <div v-if="cancelledConsultations.length > 0">
              <table
                class="table table-hover table-vertical-align mb-0 bg-white"
                :class="compact ? 'table-sm' : ''"
              >
                <thead>
                  <tr>
                    <th scope="col" class="w-50" style="border-top: 0px">
                      Type
                    </th>
                    <th scope="col" style="border-top: 0px">Method</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(c, index) in cancelledConsultations" :key="index">
                    <td class="text-capitalize w-50">
                      {{ c.service_name }}
                      <p class="small mb-0 pb-0">
                        {{ c.practitioner_first_name }}
                        {{ c.practitioner_last_name }}
                      </p>
                      <p class="small mb-0 pb-0">
                        {{
                          c.clinic_package_id === null
                            ? "Service"
                            : "Package: " + c.package_name
                        }}
                      </p>
                    </td>
                    <td class="text-capitalize">
                      {{ c.method ? c.method : "TBA" | formatMethod }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="text-center">
              <div class="text-center">
                <busy :visible="loading" />
                <p v-if="!loading">No cancelled consultations.</p>
              </div>
            </div>
          </div>
        </div>

        <book-modal
          v-if="showBooking"
          :user="user"
          :client="client"
          @saved="fetchConsultations"
          @closed="showBooking = false"
        >
        </book-modal>
        <confirm-modal
            v-if="consultationToConfirm"
          :user="user"
          :client="client"
          :consultation-id="consultationToConfirm"
          @saved="consultationConfirmed"
          @closed="consultationToConfirm = null"
        >
        </confirm-modal>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import { DatePicker } from "@jaythegeek/crmtoolkit";
import { CurrencyInput } from "vue-currency-input";
import BookModal from "./consultations-partials/BookModal";
import ConfirmModal from "./consultations-partials/ConfirmModal";
import UtcFlatPickr from "@/views/globals/UtcFlatPickr";
export default {
  props: ["compact", "clientObj"],
  data() {
    return {
      client: null,
      loading: true,
      saving: false,
      showBooking: false,
      consultations: [],
      reviewConsultation: null,
      consultationToConfirm: null,
    };
  },
  methods: {
    deleteConsult(consult) {
      if (
        confirm(
          "Are you sure you wish to remove this consultation & its related appointment & order? If you wish to reschedule you can do so from the consultation view."
        )
      ) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/consultations/" +
              consult.id +
              "/delete"
          )
          .then(({ data }) => {
            this.fetchConsultations();
            this.$EventBus.$emit("alert", data);
          });
      }
    },
    fetchConsultations() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/consultations"
        )
        .then(({ data }) => {
          this.consultations = data;
          this.loading = false;
        });
    },
    confirmConsultation(consultation) {
      this.consultationToConfirm = consultation.id;
      $("#consultationConfirmModal").modal("show");
    },
    consultationConfirmed() {
      this.fetchConsultations();
      this.consultationToConfirm = null;
      $("#consultationConfirmModal").modal("hide");
    },
    viewConsultation(c) {
      this.$router.push("/consultations/" + c.id);
    },
    async fetchClientData() {

      if(this.clientObj) {
        this.client = this.clientObj;
        return;
      }

      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.clientObj ? this.clientObj.id : this.$route.params.id;
    await this.fetchClientData();
    this.fetchConsultations();
    this.$EventBus.$on("eventAdded", this.fetchConsultations);
  },
  filters: {
    formatDate(date) {
      return moment.utc(date).local().format("lll");
    },
    formatStatus(status) {
      if (status) {
        return status.replace(/_/g, " ");
      } else {
        return "N/A";
      }
    },
    formatMethod(str) {
      if (str === 'standard') {
        return 'In Person'
      } else {
        return str;
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    upcomingConsultations() {
      return this.consultations.filter(function (consultation) {
        if (consultation.event_start) {
          return (
            moment(consultation.event_start) >= moment().startOf("day") &&
            consultation.status !== "cancelled" &&
            consultation.confirmed
          );
        } else {
          return false;
        }
      });
    },
    pastConsultations() {
      return this.consultations.filter(function (consultation) {
        if (consultation.event_start) {
          return (
            moment(consultation.event_start) <= moment().startOf("day") &&
            consultation.status !== "cancelled" &&
            consultation.confirmed
          );
        } else {
          return consultation.status !== "cancelled" && consultation.confirmed;
        }
      });
    },
    unconfirmedConsultations() {
      return this.consultations.filter(function (consultation) {
        return !consultation.confirmed && consultation.status !== "cancelled";
      });
    },
    cancelledConsultations() {
      return this.consultations.filter(function (consultation) {
        return consultation.status === "cancelled";
      });
    },
  },
  components: {
    UtcFlatPickr,
    ConfirmModal,
    BookModal,
    DatePicker,
    CurrencyInput,
  },
};
</script>

<style>
.date-picker .form-control,
.date-picker .form-control::placeholder {
  background-color: rgba(255, 255, 255, 0.75);
}

.table-vertical-align > tbody > tr > td {
  vertical-align: middle;
}
</style>

<template>
  <div>
    <div
      class="modal fade"
      role="dialog"
      id="consultationBookModal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg" role="document" id="bookModalDialog">
        <div class="modal-content bg-white" id="bookModalContent">
          <div class="modal-header">
            <h5 class="modal-title client-tab-heading">
              Book a Consultation with {{ client.name }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <book-consultation
              :client="client"
              @busy="setBusy"
              @saved="saved"
            ></book-consultation>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookConsultation from "../../../../components/booking/BookConsultation";
export default {
  props: ["user", "client"],
  data() {
    return {
      busy: false,
    };
  },
  methods: {
    saved() {
      this.$emit("saved");
    },
    setBusy(busy) {
      this.busy = busy;
    },
    bookConsultation() {
      if (!this.busy) {
        this.$EventBus.$emit("addEvent:bookConsultation");
      }
      this.busy = true;
    },
    cancel() {
      this.$EventBus.$emit("addEvent:cancel");
    },
  },
  mounted() {
    $("#consultationBookModal").modal("show");
    $("#consultationBookModal").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });
  },
  components: {
    BookConsultation,
  },
};
</script>

<style>
.shortcut-buttons-flatpickr-buttons {
  justify-content: center;
  width: 100%;
}
.shortcut-buttons-flatpickr-button {
  border-radius: 0.2rem;
  background-color: white;
  border: 0px;
  margin-bottom: 10px;
  padding: 5px;
}

@media only screen and (max-height: 600px) {
  #bookModalDialog {
    height: 100% !important;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    margin: 0;
  }

  #bookModalContent {
    min-height: 100%;
  }
}

#consultationBookModal {
  z-index: 99999;
}
</style>

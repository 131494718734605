<template>
  <div class="row mb-3 noprint">
    <div class="col-8 text-center mx-auto">
      <div class="card bg-light mt-4">
        <div class="card-body text-center">
          <div v-if="!busySending">
            <div v-if="requests.length === 0">
              <i class="far fa-info-circle fa-3x text-primary mb-3"></i>
              <p class="mb-3">No forms have been shared with this client yet.</p>
            </div>
            <form-select v-model="formToSend" class="mb-3"></form-select>
            <div class="form-check mb-1">
              <input class="form-check-input"
                     type="radio"
                     id="client"
                     value="client"
                     v-model="completedBy">
              <label class="form-check-label">
                Send to client for completion
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input"
                     type="radio"
                     id="practitioner"
                     value="practitioner"
                     v-model="completedBy">
              <label class="form-check-label">
                Complete on behalf of client
              </label>
            </div>
            <button
                :disabled="!formToSend.id || busySending"
                class="btn btn-primary mt-3"
                @click="sendFormToClient"
            >
              <busy v-if="busySending" button />

              <span v-if="completedBy === 'client'">
                Send Form to Client
              </span>
              <span v-else>
                Save Form to Client Profile
              </span>
            </button>
          </div>
          <div v-else>
            <div class="row my-5" v-if="busySending">
              <busy />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/views/globals/FormSelect";
export default {
  props: ["client", "requests"],
  data() {
    return {
      busySending: false,
      formToSend: {},
      completedBy: "client",
    };
  },
  methods: {
    sendFormToClient() {
      this.busySending = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/forms/send",
          {
            form: this.formToSend.id,
            completed_by: this.completedBy
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$emit("refresh");
          this.busySending = false;
        });
    },
  },
  created() {
  },
  components: {
    FormSelect
  },
};
</script>

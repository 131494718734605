<template>
  <div>
    <div class="row">
      <div class="col text-center">
        <div class="card cursor-pointer" @click="consultationForm.booking_type='service'"
             :class="consultationForm.booking_type === 'service' ? 'bg-primary text-white' : ''">
          <div class="card-body">
            <p class="mb-0"><i class="far fa-cube fa-3x mb-1" :class="consultationForm.booking_type === 'service' ? 'text-white' : 'text-primary'"></i></p>
            <p class="mt-0">Services</p>
          </div>
        </div>
      </div>
      <div class="col text-center">
        <div class="card cursor-pointer"
             :class="consultationForm.booking_type === 'package' ? 'bg-primary text-white' : ''"
             @click="consultationForm.booking_type = 'package'">
          <div class="card-body">
            <p class="mb-0"><i class="far fa-cubes fa-3x mb-1" :class="consultationForm.booking_type === 'package' ? 'text-white' : 'text-primary'"></i></p>
            <p class="mt-0">Packages</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3" v-if="consultationForm.booking_type">
      <div v-if="consultationForm.booking_type === 'service'">
        <select-service v-model="consultationForm.service" />
        <div v-if="consultationForm.service" class="mt-3">
          <div v-if="consultationForm.service.practitioner_services.length === 0">
            <p class="text-center">No practitioners are offering this service.</p>
          </div>
          <div v-else>
            <select class="form-control" v-model="consultationForm.practitioner_service">
              <option disabled :value="null">Select a practitioner...</option>
              <option v-for="practitioner_service in consultationForm.service.practitioner_services"
                      :value="practitioner_service">{{practitioner_service.user.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div v-else-if="consultationForm.booking_type === 'package'">
        <select-package v-model="consultationForm.package" />
      </div>
    </div>
  </div>
</template>

<script>
import SelectService from "../../services/SelectService";
import SelectPackage from "../../packages/SelectPackage";
export default {
  props: ["value"],
  data() {
    return {
      consultationForm: this.value,
    };
  },
  watch: {
    consultationForm: {
      handler: function(val) {
        if (this.consultationForm.booking_type === 'service' && this.consultationForm.practitioner_service) {
          this.consultationForm.duration = this.consultationForm.practitioner_service.duration;
        }
        this.$emit("input", this.consultationForm);
      },
      deep: true
    },
    selectedService() {
      this.consultationForm.practitioner_service = null;
    }
  },
  computed: {
    selectedService() {
      return this.consultationForm.service;
    }
  },
  components: {
    SelectPackage,
    SelectService
  },
};
</script>

<style>
</style>

<template>
  <div>
    <files-tab :user="user" :clientObj="client" :compact="true"></files-tab>
  </div>
</template>

<script>
import FilesTab from "../../clients/views/Files";

export default {
  props: ["client", "user"],
  filters: {
    dateFormat(date) {
      return moment.utc(date).format("LLL");
    },
  },
  methods: {
    close() {
      this.$emit("closed");
    },
  },
  components: {
    FilesTab,
  },
};
</script>

<style>
</style>

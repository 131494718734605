<template>
  <!-- Controls -->
  <div class="flex flex-wrap w-full justify-start">
    <button
      class="btn-circle btn-circle-xl mx-2 md:me-2 btn-dark font-bold text-center"
      v-if="!audioMuted"
      @click="toggleAudioMute"
      v-tooltip.top="'Your mic is currently on, click to mute'"
    >
      <i class="far fa-microphone"></i>
    </button>
    <button
      class="btn-circle btn-circle-xl mx-2 md:me-2 btn-light font-bold text-center"
      v-if="audioMuted"
      @click="toggleAudioMute"
      v-tooltip.top="'Your mic is currently off, click to unmute'"
    >
      <i class="far fa-microphone-slash"></i>
    </button>

    <!-- Camera -->
    <button
      class="btn-circle btn-circle-xl mx-2 md:me-2 btn-dark font-bold text-center"
      v-if="!videoEnabled"
      @click="toggleVideoMute"
      v-tooltip.top="'Your camera is currently on, click to turn it off'"
    >
      <i class="far fa-video"></i>
    </button>
    <button
      class="btn-circle btn-circle-xl mx-2 md:me-2 btn-light font-bold text-center"
      v-if="videoEnabled"
      @click="toggleVideoMute"
      v-tooltip.top="'Your camera is currently off, click to turn it on'"
    >
      <i class="far fa-video-slash"></i>
    </button>
    <!-- End camera -->

    <button
      class="hidden md:block btn-circle btn-circle-xl mx-2 md:me-2 btn-success font-bold text-center"
      v-if="screenShareSupported && !localScreenShare && !sharingScreen"
      :disabled="sharingScreen || screenButtonBusy"
      @click="startScreenShare"
      v-tooltip.top="'You are not sharing your screen, click to begin sharing'"
    >
      <i class="far fa-desktop"></i>
    </button>
    <button
      class="hidden md:block btn-circle btn-circle-xl mx-2 md:me-2 btn-danger font-bold text-center"
      v-if="screenShareSupported && localScreenShare"
      :disabled="screenButtonBusy"
      @click="endScreenShare"
      v-tooltip.top="
        'You are currently sharing your screen, click to stop sharing'
      "
    >
      <i class="far fa-desktop"></i>
    </button>
    <!--
    <button
      class="btn-circle btn-circle-xl mx-2 md:me-2 btn-success text-white font-bold text-center"
      @click="publishOwnFeed(true)"
      v-if="!published"
    >
      <i class="far fa-play"></i>
    </button> -->
    <!-- <button
      class="btn-circle btn-circle-xl mx-2 md:me-2 btn-danger font-bold text-center"
      @click="endConnection"
      v-if="connected"
      v-tooltip.top="'Leave the video chat'"
    >
      <i class="far fa-stop"></i>
    </button> -->
  </div>
  <!-- End controls -->
</template>

<script>
export default {
  props: [
    "audioMuted",
    "videoEnabled",
    "localScreenShare",
    "sharingScreen",
    "connected",
    "screenButtonBusy",
  ],
  data() {
    return {
      screenShareSupported: true,
    }
  },
  methods: {
    toggleAudioMute() {
      this.$emit("toggleAudioMute");
    },
    toggleVideoMute() {
      this.$emit("toggleVideoMute");
    },
    startScreenShare() {
      this.$emit("startScreenShare");
    },
    endScreenShare() {
      this.$emit("endScreenShare");
    },
    endConnection() {
      this.$emit("endConnection");
    },
  },
  beforeMount() {
    this.screenShareSupported = !!navigator.mediaDevices.getDisplayMedia;
  },
};
</script>

<style>
</style>
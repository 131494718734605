<template>
  <div class="modal fade" id="modal-download-zip" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-space-between align-items-center"
        >
          <h5 class="mb-0 text-primary">Download files as ZIP</h5>
        </div>

        <!-- Modal Body -->
        <div
          class="modal-body bg-white"
          style="max-height: 75vh; overflow: scroll"
        >
          <div class="row">
            <div class="col">
              <p>
                You can request a ZIP file containing all files belonging to
                this client.
              </p>
              <p>
                Your request will be processed immediately and a download link
                sent to your email:
              </p>
              <b>{{ user.email }}</b>
              <p>
                Please allow up to 15 minutes for the request to finish
                processing.
              </p>
              <p>
                You will receive a notification you when your request is
                complete.
              </p>
            </div>
          </div>
        </div>

        <!-- Modal Actions -->
        <div class="modal-footer py-2 bg-white">
          <button
            type="button"
            class="btn btn-outline-secondary me-auto"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary float-end me-2"
            @click="performDownload"
          >
            Download ZIP
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: ["client"],
  data() {
    return {
      sending: false,
    };
  },
  methods: {
    performDownload() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/files/download-zip"
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          $("#modal-download-zip").modal("hide");
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {},
  mounted() {
    $("#modal-download-zip").modal("show");

    $("#modal-download-zip").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });
  },
  filters: {},
  components: { VueEditor },
};
</script>

<style>
</style>

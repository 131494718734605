<template>
  <div>
    <div class="row">
      <div class="col-6">
        <currency-input
            v-model="consultationForm.price"
            :disabled="consultationForm.allow_instalments"
            :currency="currency.name"
            locale="gb"
            class="form-control"
            placeholder="Enter a Price (optional)"
            id="consultPrice"
            :min="0"
        />
      </div>
      <div class="col-6 my-auto">
        <toggle-button v-model="consultationForm.allow_instalments"
                       :sync="true"
                       class="my-auto"
                       :color="'#03006b'" />
        <label class="custom-control-label ms-1">
          Payment via Instalments
        </label>
      </div>
    </div>
    <div v-if="consultationForm.allow_instalments">
      <div class="card mt-3">
        <div class="card-body">
          <table class="table my-1">
            <thead class="thead">
            <th scope="col" style="border-top: 0px;">Payment Amount</th>
            <th scope="col" style="border-top: 0px;">Payment Due Date</th>
            <th scope="col" style="border-top: 0px;"></th>
            </thead>
            <tbody>
            <tr v-for="(instalment, index) in consultationForm.instalments">
              <td>
                <currency-input
                    v-model="instalment.total"
                    :currency="currency.name"
                    locale="gb"
                    class="form-control d-inline"
                    placeholder="Enter a price"
                    :min="0"
                    required
                />
              </td>
              <td>
                <flat-pickr
                    :value="instalment.due_date"
                    v-model="instalment.due_date"
                    class="form-control bg-white"
                    placeholder="Payment Due Date (Optional)"
                    :config="dateConfigNoTime"
                    name="date"
                ></flat-pickr>
              </td>
              <td class="text-center">
                <i class="far fa-times-circle text-danger cursor-pointer pt-2" @click="removeInstalment(index)"></i>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col">
              <button class="btn btn-outline-primary btn-sm float-end me-3" @click="addInstalment">
                <i class="far fa-plus me-1"></i>
                Add Instalment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import { CurrencyInput } from "vue-currency-input";

export default {
  props: ["value"],
  data() {
    return {
      consultationForm: this.value
    };
  },
  methods: {
    addInstalment() {
      this.consultationForm.instalments.push({});
      this.syncPriceWithInstalments();
    },
    removeInstalment(index) {
      if (this.consultationForm.instalments.length > 1){
        this.consultationForm.instalments.splice(index, 1);
      }
    },
    getDefaultPrice() {
      if (this.consultationForm.booking_type === 'service'){
        return this.consultationForm.practitioner_service.booking_options.price ?? 0;
      } else if (this.consultationForm.booking_type === 'package'){
        return this.consultationForm.package.booking_options.price ?? 0;
      }
    },
    getDefaultAllowInstalments() {
      if (this.consultationForm.booking_type === 'service'){
        return this.consultationForm.practitioner_service.booking_options.allow_instalments
      } else if (this.consultationForm.booking_type === 'package'){
        return this.consultationForm.package.booking_options.allow_instalments;
      }
    },
    getDefaultInstalments() {
      if (this.consultationForm.booking_type === 'service'){
        if (this.consultationForm.practitioner_service.instalments.length > 0){
          return this.consultationForm.practitioner_service.instalments;
        } else {
          return [{total: this.getDefaultPrice()}];
        }
      } else if (this.consultationForm.booking_type === 'package'){
        if (this.consultationForm.package.booking_options.allow_instalments && this.consultationForm.package.instalments.length > 0){
          return this.consultationForm.package.instalments;
        } else {
          return [{total: this.getDefaultPrice()}];
        }
      }
    },
    syncPriceWithInstalments() {
      if (this.consultationForm.allow_instalments){
        this.consultationForm.price = this.consultationForm.instalments.reduce(function (total, val) {
          if (val && val.total) {
            return total + val.total;
          } else {
            return total;
          }
        }, 0)
      }
    },
  },
  computed: {
    dateConfigNoTime() {
      return {
        altFormat: 'J F Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: false,
        allowInput: true,
        plugins: [
          ShortcutButtonsPlugin({
            button: {
              class: 'btn form-control',
              label: 'Clear',
            },
            onClick: (index, fp) => {
              fp.clear();
              fp.close();
            }
          })
        ]
      };
    },
    currency() {
      if (this.consultationForm.booking_type === 'service'){
        return this.consultationForm.practitioner_service.currency;
      } else if (this.consultationForm.booking_type === 'package'){
        return this.consultationForm.package.currency;
      }
    },
  },
  mounted() {
    this.consultationForm.price = this.getDefaultPrice();
    this.consultationForm.allow_instalments = this.getDefaultAllowInstalments();
    this.consultationForm.instalments = this.getDefaultInstalments();
  },
  filters: {
  },
  components: {
    flatPickr,
    CurrencyInput
  },
  watch: {
    price(newVal) {
      this.consultationForm.instalments = [{total: newVal}];
    },
    consultationForm: {
      handler: function(val) {
        this.syncPriceWithInstalments();
        this.$emit("input", this.consultationForm);
      },
      deep: true
    },
  }
};
</script>

<style>
</style>

<template>
  <div>
    <busy v-if="loading" />

    <div v-else-if="mymopPlans.length === 0">
      <div class="row mt-4">
        <div class="col-8 mx-auto text-center">
          <div class="card bg-light">
            <div class="card-body">
              <i class="far fa-mind-share fa-4x text-primary"></i>
              <p>No Health Tracker plans have been created for this client yet.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="row mt-4">
      <div class="col-8 mx-auto">
        <div
          class="card cursor-pointer bg-light mb-3"
          v-for="p in mymopPlans"
          @click="openPlan(p)"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-auto">
                <i class="far fa-mind-share fa-4x text-primary my-auto"></i>
              </div>
              <div class="col">
                <span v-if="p.active" class="badge bg-info text-capitalize"
                  >Active</span
                >
                <span v-else class="badge bg-dark text-capitalize"
                  >Disabled</span
                >
                <i v-if="p.end_date" class="small">
                  (End Date: {{ p.end_date | formatDate }})
                </i>
                <p class="mb-0">
                  {{ p.title }} <i>(Every {{ p.frequency_days }} days)</i>
                </p>
                <div class="position-absolute" style="top: 0px; right: 7px">
                  <button
                    class="btn btn-outline-danger btn-sm"
                    v-if="p.active"
                    @click.stop="disablePlan(p)"
                  >
                    <i
                      class="far fa-power-off cursor-pointer me-1"
                      v-tooltip.bottom="'Disable Plan'"
                    ></i>
                    Disable
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from '../../../../../components/Busy.vue';
export default {
  props: ["client", "user", "updateRequired"],
  data() {
    return {
      loading: true,
      mymopPlans: [],
      selectedPlan: null,
    };
  },
  methods: {
    fetchMymopPlans() {
      this.loading = true;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/mymop"
        )
        .then(({ data }) => {
          this.mymopPlans = data;
          this.loading = false;
          this.$emit("refreshed");
        });
    },
    openPlan(plan) {
      this.$emit("selected", plan);
    },
    disablePlan(plan) {
      var confirmed = confirm(
        "Are you sure you wish to disable this Health Tracker plan? Data will not be deleted but the client will no longer receive requests to complete the Health Tracker questions."
      );
      if (confirmed) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              this.client.id +
              "/mymop/" +
              plan.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchMymopPlans();
          });
      }
    },
  },
  mounted() {
    this.fetchMymopPlans();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  watch: {
    updateRequired(newVal, oldVal) {
      if (newVal) {
        this.fetchMymopPlans();
      }
    },
  },
  computed: {},
  components: {},
};
</script>

<style>
</style>

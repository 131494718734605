<template>
  <div class="documentsTab h-100">
    <div v-if="!template">
      <div class="row mb-3">
        <div class="col-md-4 my-auto ms-auto pe-0">
          <select v-model="selectedTemplateId" class="form-control">
            <option value>Select a template to edit from</option>
            <option :value="t.id" v-for="t in templates" :key="t.id">
              {{ t.title }}
            </option>
          </select>
        </div>
        <div class="col-auto my-auto">
          <button class="btn btn-primary" :disabled="!selectedTemplateId" @click="cloneAndLoad">
            Edit before sending
          </button>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <p class="alert alert-light">
            <i class="far fa-info me-2"></i>Select a template that you have
            pre-created or create one by
            <router-link to="/health-reports/create" id="Create-Template-Link"
              >clicking here</router-link
            >. You may then add data to your current template, edit the template
            in anyway for this specific client. You may preview the PDF exactly
            as it will be viewed by the client before sending. Once the PDF is
            shared with the client portal, you may edit it, this will overwrite
            the currently shared file with a newly updated one.
          </p>
        </div>
      </div>
    </div>

    <!--  -->

    <table
      class="table table-sm table-hover"
      v-if="templates.length > 0 && !template"
    >
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Title</th>
          <th scope="col">Status</th>
          <th scope="col">Issued to Client</th>
          <th scope="col">Created</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="t in clientTemplates" :key="t.id" class="cursor-pointer">
          <th @click="loadTemplate(t)" scope="row">#{{ t.id }}</th>
          <td v-if="editItem !== t" @click="loadTemplate(t)">{{ t.title }}</td>
          <td v-else><input type="text" v-model="t.title" /></td>
          <td @click="loadTemplate(t)" class="text-capitalize">
            <span class="badge bg-primary badge-pill">{{
              t.status | formatStatus
            }}</span>
          </td>
          <td @click="loadTemplate(t)">
            {{ t.issued_to_client_at | formatDate }}
          </td>
          <td @click="loadTemplate(t)">{{ t.created_at | formatDate }}</td>
          <td>
            <button
              class="btn btn-xs btn-light"
              @click="editTitle(t)"
              v-if="editItem !== t"
            >
              Rename
            </button>
            <button class="btn btn-xs btn-light" @click="editTitle(t)" v-else>
              Save
            </button>
            <button class="btn btn-xs btn-light" @click="CopyTemplate(t)">
              Copy
            </button>
          </td>
          <td>
            <button
              class="btn btn-xs btn-light"
              @click="deleteClientTemplate(t.id)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!--  -->

    <div class="row" v-if="template">
      <div class="col">
        <mini-client-editor
          :user="user"
          :client="client"
          :input-view="true"
          :templateId="template.id"
        ></mini-client-editor>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
import MiniClientEditor from "./MiniClientEditor";

export default {
  props: ["client", "user"],
  components: { MiniClientEditor },
  data() {
    return {
      selectedTemplateId: "",
      templates: [],
      clientTemplates: [],
      template: null,
      editItem: null,
    };
  },
  methods: {
    CopyTemplate(item) {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/documents/client-templates/copy",
          {
            target: item.id,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchClientTemplates();
        });
    },
    editTitle(item) {
      if (this.editItem == item) {
        this.$axios
          .put(
            process.env.VUE_APP_API_URL +
              "/documents/client-templates/" +
              this.editItem.id,
            {
              title: item.title,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.editItem = null;
            this.fetchClientTemplates();
          });
      } else {
        this.editItem = item;
      }
    },
    loadTemplate(t) {
        this.template = t;
    },
    fetchTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/documents/fetch")
        .then(({ data }) => {
          this.templates = data;
        });
    },
    fetchClientTemplates() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/documents/fetch-client-templates/" +
            this.client.id
        )
        .then(({ data }) => {
          this.clientTemplates = data;
        });
    },
    deleteClientTemplate(id) {
      var confirmed = confirm(
        "Are you sure you wish to delete this client template, this action cannot be undone?"
      );
      if (confirmed) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/documents/client-version/" + id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchClientTemplates();
          });
      }
    },
    cloneAndLoad() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/documents/" +
            this.selectedTemplateId +
            "/attach-template-to-client/" +
            this.client.id
        )
        .then(({ data }) => {
          this.template = data.template;
          console.log("loaded");
        });
    },
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("LL");
      }
      return "-";
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
  mounted() {
    this.fetchTemplates();
    this.fetchClientTemplates();

    this.$EventBus.$on("closeLoadedTemplate", () => {
      this.template = null;
    });
  },
};
</script>

<style scoped>
#Create-Template-Link {
  text-decoration: underline;
  font-weight: 900;
}
</style>

<template>
  <div>
    <utc-flat-pickr v-model="consultationForm.start" :req="true" />
    <div v-if="consultationForm.start && blockingEvents.length > 0" class="alert alert-danger mb-0 mt-3">
      <p>
        <i class="far fa-info-circle me-1"></i>
        Your calendar has events which overlap with this slot:
      </p>
      <ul class="ms-5">
        <li v-for="e in blockingEvents">
          {{e.title}}
          <span v-if="e.client"> with {{e.client.name}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import UtcFlatPickr from "../../../globals/UtcFlatPickr";
export default {
  props: ["value", "preSelectedDate"],
  data() {
    return {
      consultationForm: this.value,
      blockingEvents: [],
    };
  },
  methods: {
    checkDateAvailability() {
      return this.$axios.post(process.env.VUE_APP_API_URL + "/events/check-availability",
          {
            user_id: this.consultationForm.practitioner_service.user_id,
            start: this.consultationForm.start,
            duration: this.consultationForm.practitioner_service.duration,
          }
      ).then(({data}) => {
        this.blockingEvents = data;
      });
    },
  },
  mounted() {
    if(this.preSelectedDate) {
      this.consultationForm.start = this.preSelectedDate.start;
    }
  },
  filters: {
  },
  components: {
    UtcFlatPickr
  ,
  },
  watch: {
    consultationForm: {
      handler: function(val) {
        if (this.consultationForm.booking_type === 'service') {
          this.checkDateAvailability();
        }
        this.$emit("input", this.consultationForm);
      },
      deep: true
    },
  }
};
</script>

<style>
</style>

<template>
  <div class="d-flex">
    <div v-for="m in methods">
      <input type="radio"
             :id="m.name + randomSeed"
             :name="m.name + randomSeed"
             :value="m.name"
             v-model="method"
             class="btn-check"
             :required="!method && required"
      >
      <label :for="m.name + randomSeed" class="btn me-2" :class="method === m.name ? 'btn-primary' : 'btn-outline-primary'">
        {{m.display}}
      </label>

    </div>

  </div>
</template>

<script>
export default {
  props: ["value", "required"],
  data() {
    return {
      methods: [
        { name: 'standard', display: 'In Person'},
        { name: 'video', display: 'Video'},
        { name: 'phone', display: 'Phone'}
      ],
      method: this.value,
      randomSeed: Math.random()
    };
  },
  watch: {
    method() {
      this.$emit("input", this.method)
    }
  },
};
</script>

<style>
</style>

<template>
  <div>
    <Preflight
      v-if="!checkCompatabilityComplete"
      :success="onCompatabilityChecksSuccess"
    />
    <div
      class="vh-100 w-100 flex-column bg-primary"
      :class="consultation && checkCompatabilityComplete ? 'd-flex' : 'd-none'"
    >
      <div class="flex-shrink-0" v-if="consultation">
        <div class="p-2 bg-light border-bottom">
          <div class="row">
            <div class="col-auto my-auto">
              <button
                class="btn btn-outline-primary"
                @click="isSideBarOpen = !isSideBarOpen"
              >
                <i class="far fa-bars"></i>
              </button>
            </div>
            <div class="col-auto ms-2 me-auto my-auto">
              <h5 class="mb-0 fw-bold">
                {{ consultation.service.name }} with
                {{ consultation.client.name }} -
                {{ consultation.start | formatDateUtc }}
              </h5>
            </div>
            <div class="col-auto my-auto ms-auto">
              <p class="mb-0">
                <button class="btn btn-danger" @click="endConsultation">
                  <i class="far fa-portal-exit me-2"></i>End Consultation
                </button>
                <feedback-modal ref="FBModal" :consultation="consultation" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters flex-grow-1 w-100" style="min-height: 0">
        <div
          class="col-6 bg-white"
          style="height: 100%; max-height: 100%; overflow: scroll"
          :class="isSideBarOpen ? 'col-6' : 'd-none'"
          v-if="consultation"
        >
          <div class="p-2">
            <div class="details-section" v-if="!currentTab">
              <div class="row mt-2 mb-3 text-center">
                <div class="col-6">
                  <i class="far fa-concierge-bell fa-2x text-primary"></i>
                  <hr width="50%" class="mx-auto my-2" />
                  <small class="fw-bold">{{ consultation.service.name }}</small>
                </div>
                <div class="col-6">
                  <i class="far fa-user fa-2x text-primary"></i>
                  <hr width="50%" class="mx-auto my-2" />
                  <small class="fw-bold">{{ consultation.client.name }}</small>
                </div>
              </div>

              <div class="row text-center mb-4">
                <div class="col-6">
                  <i class="far fa-calendar fa-2x text-primary"></i>
                  <hr width="50%" class="mx-auto my-2" />
                  <small class="fw-bold">
                    {{ consultation.client.dob | formatDate }}</small
                  >
                </div>
                <div class="col-6">
                  <i class="far fa-envelope fa-2x text-primary"></i>
                  <hr width="50%" class="mx-auto my-2" />
                  <small class="fw-bold">
                    {{ consultation.client.email }}</small
                  >
                </div>
              </div>
            </div>

            <div class="row mt-2 text-center">
              <div class="col">
                <button
                  class="btn btn-outline-primary m-2 btn-sm"
                  :class="currentTab == 'notes' ? 'active' : ''"
                  @click="switchTab('notes')"
                >
                  Notes
                </button>
                <button
                  class="btn btn-outline-primary m-2 btn-sm"
                  :class="currentTab == 'timeline' ? 'active' : ''"
                  @click="switchTab('timeline')"
                >
                  Timelines
                </button>

                <button
                  class="btn btn-outline-primary m-2 btn-sm"
                  :class="currentTab == 'mymop' ? 'active' : ''"
                  @click="switchTab('mymop')"
                >
                  MyMOP
                </button>
                <button
                  class="btn btn-outline-primary m-2 btn-sm"
                  :class="currentTab == 'healthdiary' ? 'active' : ''"
                  @click="switchTab('healthdiary')"
                >
                  Health Diary
                </button>

                <button
                  class="btn btn-outline-primary m-2 btn-sm"
                  :class="currentTab == 'consultations' ? 'active' : ''"
                  @click="switchTab('consultations')"
                >
                  Consultations
                </button>

                <button
                  class="btn btn-outline-primary m-2 btn-sm"
                  :class="currentTab == 'files' ? 'active' : ''"
                  @click="switchTab('files')"
                >
                  Files
                </button>

                <button
                  class="btn btn-outline-primary m-2 btn-sm"
                  :class="currentTab == 'forms' ? 'active' : ''"
                  @click="switchTab('forms')"
                >
                  Forms
                </button>

                <button
                  class="btn btn-outline-primary m-2 btn-sm"
                  :class="currentTab == 'health-report' ? 'active' : ''"
                  @click="switchTab('health-report')"
                >
                  Health Reports
                </button>

                <button
                  class="btn btn-outline-dark m-2 btn-sm"
                  v-if="currentTab"
                  @click="closeTabs"
                >
                  <i class="far fa-times me-2"></i>Close
                </button>
              </div>
            </div>

            <hr class="my-3" />

            <!-- <div class="row">
              <div class="col">
                <textarea
                  type="text"
                  rows="4"
                  class="form-control"
                  placeholder="Add a note client profile..."
                ></textarea>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-auto ms-auto">
                <button class="btn btn-sm btn-primary disabled">
                  <i class="far fa-plus me-2"></i>Add Note
                </button>
              </div>
            </div> -->

            <notes-tab
              :client="consultation.client"
              :user="$store.getters['auth/user']"
              v-if="currentTab == 'notes'"
            ></notes-tab>

            <timelines-tab
              :client="consultation.client"
              :user="$store.getters['auth/user']"
              v-if="currentTab == 'timeline'"
            ></timelines-tab>

            <my-mop-tab
              :client="consultation.client"
              :user="$store.getters['auth/user']"
              v-if="currentTab == 'mymop'"
            ></my-mop-tab>

            <health-diary-tab
              :client="consultation.client"
              :user="$store.getters['auth/user']"
              v-if="currentTab == 'healthdiary'"
            ></health-diary-tab>

            <consultations-tab
              :client="consultation.client"
              :user="$store.getters['auth/user']"
              v-if="currentTab == 'consultations'"
            ></consultations-tab>

            <files-tab
              :client="consultation.client"
              :user="$store.getters['auth/user']"
              v-if="currentTab == 'files'"
            ></files-tab>

            <forms-tab
              :client="consultation.client"
              :user="$store.getters['auth/user']"
              v-if="currentTab == 'forms'"
            ></forms-tab>

            <file-sharing-tab
              :client="consultation.client"
              :user="$store.getters['auth/user']"
              :file="fileBeingShared"
              v-if="currentTab == 'file-sharing'"
            ></file-sharing-tab>

            <health-report-tab
              :client="consultation.client"
              :user="$store.getters['auth/user']"
              v-if="currentTab == 'health-report'"
            >
            </health-report-tab>
          </div>
        </div>
        <div class="col h-100 bg-black" v-show="consultation">
          <janus-video-room
            ref="videoRoom"
            :room-data="{ id: $route.params.id }"
            :user-data="$store.getters['auth/user']"
            :stream-enabled="false"
            @startScreenShare="startScreenShare($event)"
            @endScreenShare="endScreenShare($event)"
            @toggleAudioMute="toggleAudioMute($event)"
            @toggleVideoMute="toggleVideoMute($event)"
          ></janus-video-room>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MicTest } from "./MicTest.js";
import JanusVideoRoom from "./JanusVideoRoom";
import Preflight from "./Preflight";
import NotesTab from "./client-partials/NotesTab";
import TimelinesTab from "./client-partials/TimelinesTab";
import MyMopTab from "./client-partials/MyMopTab";
import HealthDiaryTab from "./client-partials/HealthDiaryTab";
import ConsultationsTab from "./client-partials/ConsultationsTab";
import FilesTab from "./client-partials/FilesTab";
import FormsTab from "./client-partials/FormsTab";
import FileSharingTab from "./client-partials/FileSharingTab";
import HealthReportTab from "./client-partials/HealthReportTab";
import * as io from "socket.io-client";
import FeedbackModal from "./Feedback";

export default {
  data() {
    return {
      checkCompatabilityComplete: false,
      isSideBarOpen: true,
      consultation: null,
      //
      currentTab: null,

      //
      isConnected: false,
      socketMessage: "",
      socket: null,

      //
      fileBeingShared: null,

      dataReport: {
        user: this.$store.getters['auth/user'].id,
        room: this.$route.params.id,
        userAgent: navigator.userAgent,
        events: [],
      },

      p2pSocket: null,
      localStream: null,
      remoteStream: null,
      socketInitialized: false,
      peerConnections: {},
      pcOptions: {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1,
      },
      candidates: [],
      users: [],
      userContact: -1,
      peerConnectionTimers: {},
      packetsLostThreshold: 25,
    };
  },
  methods: {
    fileShared(data) {
      console.log("file shared from server", data);
    },
    sendRoomDetails() {
      console.log("sending room details");
      this.socket.emit("room", {
        room: this.$route.params.id,
        user: this.$store.getters['auth/user'],
      });
    },
    shareFile(file) {
      console.log("sharing file from client");
      this.socket.emit("sharingFile", file);
    },
    switchTab(tab) {
      this.currentTab = tab;
    },
    closeTabs() {
      this.socket.emit("fileShareEnded");
      this.currentTab = null;
      this.fileBeingShared = null;
    },
    endConsultation() {
      if (confirm("Are you sure you wish to end this consultation?")) {
        this.clearPeerconnections();
        this.sendLog();
        this.$refs.FBModal.openModal();
        //window.close();
      }
    },
    fetchClientData() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/consultations/api/" +
            this.$route.params.id
        )
        .then(async ({ data }) => {
          this.consultation = data;
          if (this.checkCompatabilityComplete && !this.socket) {
            this.initSocket();
          }
        });
    },
    sendLog() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/video-log", {
          user_id: this.dataReport.user,
          user_type: "user",
          room_id: this.dataReport.room,
          userAgent: this.dataReport.userAgent,
          events: this.dataReport.events,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    startP2PCall(callId) {
      if (this.socketInitialized) {
        this.p2pSocket.emit("connect_to_call", { callId });
      } else {
        console.log("socket not initialized");
      }
    },
    onCompatabilityChecksSuccess(mediaStream) {
      this.checkCompatabilityComplete = true;

      mediaStream.getAudioTracks()[0].enabled =
        !this.$refs.videoRoom.getAudioStatus();
      mediaStream.getVideoTracks()[0].enabled =
        !this.$refs.videoRoom.getVideoStatus();

      this.localStream = mediaStream;
      this.$refs.videoRoom.setLocalStream(this.localStream);

      this.micTest = new MicTest(this.localStream, (result) => {
        if (result || this.audioMuted) {
          this.$refs.videoRoom.hideNotification("sound");
        } else {
          this.$refs.videoRoom.notificationType = "sound";
          this.$refs.videoRoom.showNotification(
            "No sound detected, please check your microphone",
            "warning",
            4000
          );
        }
      });
      this.micTest.start();

      if (!this.socket) {
        this.initSocket();
      }
    },
    startPeerConnection(stream, isScreen) {
      isScreen = isScreen || false;

      let peerConnectionType = isScreen ? "screen" : "call";
      let peerConnection;

      peerConnection = new RTCPeerConnection({
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          {
            urls: "turn:turn.swandoola.com:443",
            username: "user",
            credential: "7VyQFJ2k98KaJ79esWHU",
            //TODO: integrate credentials with consultations
          },
        ],
      });

      this.peerConnections[peerConnectionType] = peerConnection;
      this.peerConnectionTimers[peerConnectionType] = this.monitorPcStats(
        peerConnection,
        peerConnectionType
      );

      peerConnection.addEventListener(
        "icecandidate",
        (event) => {
          this.onIceCandidate(event, isScreen);
        },
        false
      );

      peerConnection.ontrack = (event) => {
        this.ontrack(event, isScreen);
      };

      if (!isScreen) {
        peerConnection.oniceconnectionstatechange = (event) => {
          this.$refs.videoRoom.handleICEState(
            peerConnection.iceConnectionState
          );
        };
      }

      if (stream) {
        stream
          .getTracks()
          .forEach((track) => peerConnection.addTrack(track, stream));
        this.setVP8Codec(peerConnection, stream);
      }
    },
    createOffer(isScreen) {
      isScreen = isScreen || false;

      let peerConnectionType = isScreen ? "screen" : "call";
      let peerConnection = this.peerConnections[peerConnectionType];

      peerConnection
        .createOffer(this.pcOptions)
        .then((offer) => {
          return peerConnection.setLocalDescription(offer);
        })
        .then(() => {
          this.p2pSocket.emit("webrtc_info", {
            type: "offer",
            sdp: peerConnection.localDescription.sdp,
            userId: this.userContact,
            isScreen: isScreen,
          });
        })
        .catch((reason) => {
          // can't create offer
        });
    },
    onIceCandidate(event, isScreen) {
      if (event.candidate) {
        this.p2pSocket.emit("webrtc_info", {
          type: "candidate",
          candidate: event.candidate.candidate,
          sdpMid: event.candidate.sdpMid,
          sdpMLineIndex: event.candidate.sdpMLineIndex,
          userId: this.userContact,
          isScreen: isScreen,
        });
      }
    },
    ontrack(event, isScreen) {
      console.log("got new track");

      if (event.streams && event.streams[0]) {
        this.$refs.videoRoom.setRemoteStream(event.streams[0], isScreen);
      }
    },
    toggleAudioMute($event) {
      this.audioMuted = $event;

      if (this.localStream) {
        this.localStream.getAudioTracks()[0].enabled = !$event;
      }
    },
    toggleVideoMute($event) {
      if (this.localStream) {
        this.localStream.getVideoTracks()[0].enabled = !$event;
      }
    },
    initSocket() {
      this.socket = io(process.env.VUE_APP_CONSULTATION_NODE_SERVER, {
        transports: ["websocket"],
        query:
          "room_var=" +
          this.$route.params.id +
          "&user_email_var=" +
          this.$store.getters['auth/user'].email,
      });

      this.p2pSocket = io("https://turn.swandoola.com:8080", {
        transports: ["websocket"],
        forceNew: true,
        reconnection: true,
        query: {
          userId: this.$store.getters['auth/user'].id,
          userToken: "user_token_" + this.$store.getters['auth/user'].id,
          dropPreviousConnection: false,
        },
      });

      this.socket.on("connect", () => {
        console.log("connected to socket");
        // this.sendRoomDetails();
      });

      this.socket.on("fileShared", (file) => {
        console.log("file has been shared by user", file);
        this.fileBeingShared = file;
        this.switchTab("file-sharing");
      });

      this.$EventBus.$on("realTimeShareFile", (file) => {
        this.shareFile(file);
      });

      this.$EventBus.$on("consultation:ScreenSharingStarted", () => {
        this.dataReport.events.push("Event: Started ScreenSharing");
        this.closeTabs();
      });

      this.$EventBus.$on("addReport", (report) => {
        this.dataReport.events.push(report);
      });

      // new callbacks for P2P
      this.p2pSocket.on("session_initialized", () => {
        console.log("session_initialized");
        this.socketInitialized = true;
        this.startP2PCall(this.$route.params.id);
      });

      this.p2pSocket.on("connection_refused", (data) => {
        console.log("connection_refused", data);
      });

      this.p2pSocket.on("on_connect_to_call", (data) => {
        if (data.success) {
          const myId = this.$store.getters['auth/user'].id;
          this.users = data.users;
          this.users.forEach((id) => {
            if (id != myId) {
              this.userContact = id;
            }
          });
        }
      });

      this.p2pSocket.on("another_user_connected", (data) => {
        console.log("another_user_connected:", data);
        const userId = data.userId;
        this.userContact = userId;
        if (this.users.indexOf(userId) == -1) {
          this.users.push(userId);
        }

        this.startPeerConnection(this.localStream);
        this.createOffer();

        let peerConnectionExist = this.peerConnections["screen"];

        if (this.screenShareStream) {
          this.startPeerConnection(this.screenShareStream, true);

          if (!peerConnectionExist) {
            this.createOffer(true);
          }
        }
      });

      this.p2pSocket.on("webrtc_info", async (data) => {
        console.log("new message", data);

        let isScreen = data.isScreen;
        let peerConnectionType = isScreen ? "screen" : "call";
        let peerConnection = this.peerConnections[peerConnectionType];

        switch (data.type) {
          case "offer":
            if (!peerConnection) {
              let stream = isScreen ? this.screenShareStream : this.localStream;

              this.startPeerConnection(stream, isScreen);
              peerConnection = this.peerConnections[peerConnectionType];
            }

            peerConnection
              .setRemoteDescription({ type: "offer", sdp: data.sdp })
              .then(() => {
                return peerConnection.createAnswer(this.pcOptions);
              })
              .then((answer) => {
                return peerConnection.setLocalDescription(answer);
              })
              .then(() => {
                this.candidates.forEach((candidate) => {
                  peerConnection.addIceCandidate(candidate);
                });

                this.candidates = [];

                this.p2pSocket.emit("webrtc_info", {
                  type: "answer",
                  sdp: peerConnection.localDescription.sdp,
                  userId: this.userContact,
                  isScreen: isScreen,
                });
              })
              .catch((error) => {
                console.error("can't create answer:", error);
              });
            break;
          case "answer":
            try {
              await peerConnection.setRemoteDescription({
                type: "answer",
                sdp: data.sdp,
              });

              this.candidates.forEach((candidate) => {
                peerConnection.addIceCandidate(candidate);
              });

              this.candidates = [];
            } catch (error) {
              console.error("can't set remote description:", error);
            }
            break;
          case "candidate":
            if (data.candidate) {
              let candidate = new RTCIceCandidate({
                sdpMLineIndex: data.sdpMLineIndex,
                candidate: data.candidate,
              });

              if (peerConnection.remoteDescription) {
                peerConnection.addIceCandidate(candidate);
              } else {
                this.candidates.push(candidate);
              }
            }
            break;
        }
      });
      this.p2pSocket.on("user_disconnected", (data) => {
        console.log("user_disconnected", data);
        if (this.userContact == data.userId) {
          ["call", "screen"].forEach((type) => {
            clearTimeout(this.peerConnectionTimers[type]);

            if (this.peerConnections[type]) {
              let isScreen = type == "screen";

              this.peerConnections[type].close();
              this.peerConnections[type] = null;
              this.$refs.videoRoom.setRemoteStream(null, isScreen);
            }
          });
        }
      });
      this.p2pSocket.on("stream_ended", (data) => {
        console.log("stream_ended", data);

        let peerConnectionType = data.isScreen ? "screen" : "call";
        clearTimeout(this.peerConnectionTimers[peerConnectionType]);

        this.$refs.videoRoom.setRemoteStream(null, data.isScreen);

        if (this.peerConnections[peerConnectionType]) {
          let keepPeerConnection =
            data.isScreen &&
            (this.screenShareStream || this.$refs.videoRoom.screenShare);

          if (!keepPeerConnection) {
            this.peerConnections[peerConnectionType].close();
            this.peerConnections[peerConnectionType] = null;
          }
        }
      });

      this.p2pSocket.on("call_finished", (data) => {
        console.log("call_finished", data);
      });
    },
    startScreenShare($event) {
      this.screenShareStream = $event;
      let peerConnection = this.peerConnections["screen"];

      if (peerConnection) {
        this.screenShareStream
          .getTracks()
          .forEach((track) =>
            peerConnection.addTrack(track, this.screenShareStream)
          );
        this.setVP8Codec(peerConnection, this.screenShareStream);
      } else {
        this.startPeerConnection(this.screenShareStream, true);
      }

      this.createOffer(true);
    },
    endScreenShare($event) {
      this.p2pSocket.emit("stream_ended", {
        isScreen: true,
      });

      if (this.screenShareStream) {
        this.screenShareStream.getTracks().forEach((track) => {
          track.enable = false;
          track.stop && track.stop();
        });

        this.screenShareStream = null;
      }

      if (!this.$refs.videoRoom.screenShare && this.peerConnections["screen"]) {
        this.peerConnections["screen"].close();
        this.peerConnections["screen"] = null;
      }

      $event.success();
    },
    setVP8Codec(peerConnection, stream) {
      const supportsSetCodecPreferences =
        window.RTCRtpTransceiver &&
        "setCodecPreferences" in window.RTCRtpTransceiver.prototype;

      if (supportsSetCodecPreferences) {
        let preferredCodec = "video/VP8";
        const { codecs } = RTCRtpSender.getCapabilities("video");
        const selectedCodecIndex = codecs.findIndex(
          (c) => c.mimeType === preferredCodec
        );

        if (selectedCodecIndex != -1) {
          const selectedCodec = codecs[selectedCodecIndex];
          codecs.splice(selectedCodecIndex, 1);
          codecs.unshift(selectedCodec);

          const transceiver = peerConnection
            .getTransceivers()
            .find((transceiver) => {
              return (
                transceiver.sender &&
                transceiver.sender.track === stream.getVideoTracks()[0]
              );
            });
          transceiver.setCodecPreferences(codecs);
          console.log("set prefered codec", preferredCodec);
        } else {
          console.log("prefered codec not found", preferredCodec);
        }
      } else {
        console.log("setting codec not supported");
      }
    },
    clearPeerconnections() {
      ["call", "screen"].forEach((type) => {
        clearTimeout(this.peerConnectionTimers[type]);
      });

      if (this.screenShareStream) {
        this.endScreenShare();
      }

      if (this.p2pSocket) {
        this.p2pSocket.close();
        this.p2pSocket = null;
      }

      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => {
          track.stop();
        });
        this.localStream = null;
        this.$refs.videoRoom.setRemoteStream(null);
      }

      if (this.peerConnections["call"]) {
        this.peerConnections["call"].close();
        this.peerConnections["call"] = null;
      }

      this.connected = false;
    },
    monitorPcStats(pc, type) {
      let lastShown;
      let showInterval = 30000;
      let lastInfo = {
        audio: {
          packetsLost: 0,
          packetsReceived: 0,
          packetsLostPercentage: 0,
        },
        video: {
          packetsLost: 0,
          packetsReceived: 0,
          packetsLostPercentage: 0,
        },
      };

      let getPcStats = () => {
        pc.getStats(null).then((stats) => {
          let time = Date.now();
          let showPacketsWarning = false;

          stats.forEach((report) => {
            if (
              report.type === "inbound-rtp" &&
              ["audio", "video"].includes(report.kind)
            ) {
              let packetsLost =
                report.packetsLost - lastInfo[report.kind]["packetsLost"];
              let packetsReceived =
                report.packetsReceived -
                lastInfo[report.kind]["packetsReceived"];

              if (!packetsLost) {
                packetsLost = report.packetsLost;
                packetsReceived = report.packetsReceived;
              }

              let packetsLostPercentage = Math.floor(
                (packetsLost / (packetsReceived + packetsLost)) * 100
              );

              lastInfo[report.kind]["packetsLostPercentage"] =
                packetsLostPercentage;
              lastInfo[report.kind]["packetsLost"] = report.packetsLost;
              lastInfo[report.kind]["packetsReceived"] = report.packetsReceived;
            }
          });

          let audioPacketsLostPercentage =
            lastInfo["audio"]["packetsLostPercentage"];
          let videoPacketsLostPercentage =
            lastInfo["video"]["packetsLostPercentage"];

          if (audioPacketsLostPercentage >= this.packetsLostThreshold) {
            this.$refs.videoRoom.handleSlowLink(
              false,
              lastInfo["audio"]["packetsLost"]
            );
          } else if (videoPacketsLostPercentage >= this.packetsLostThreshold) {
            this.$refs.videoRoom.handleSlowLink(
              false,
              lastInfo["video"]["packetsLost"]
            );
          } else {
            this.$refs.videoRoom.hideNotification("packetsLost");
          }

          this.peerConnectionTimers[type] = setTimeout(getPcStats, 1000);
        });
      };

      getPcStats();
    },
  },
  mounted() {
    this.fetchClientData();
  },
  beforeDestroy() {
    if (this.micTest) {
      this.micTest.stop();
    }

    this.clearPeerconnections();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateUtc(date) {
      return moment.utc(date).local().format("LLL");
    },
  },
  components: {
    JanusVideoRoom,
    Preflight,
    NotesTab,
    TimelinesTab,
    MyMopTab,
    HealthDiaryTab,
    ConsultationsTab,
    FilesTab,
    FormsTab,
    FileSharingTab,
    HealthReportTab,
    FeedbackModal,
  },
};
</script>

<style>
.py-4 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

body {
  padding-top: 0rem !important;
}

.navbar {
  display: none !important;
}

.sidebar {
  display: none !important;
}

.search-for-anything,
.add-anything-menu {
  display: none !important;
}
</style>
<template>
  <div>
    <div class="row">
      <div class="col text-center">
        <button class="btn btn-light btn-sm" @click="previousPage">
          <i class="far fa-arrow-left me-2"></i>Previous Page
        </button>
        <span class="mx-3">{{ currentPage }} / {{ pageCount }}</span>
        <button class="btn btn-light btn-sm" @click="nextPage">
          <i class="far fa-arrow-right me-2"></i>Next Page
        </button>
      </div>
    </div>
    <pdf
      :src="file.url"
      :page="currentPage"
      @num-pages="pageCount = $event"
    ></pdf>
  </div>
</template>

<script>
import Pdf from "vue-pdf";

export default {
  props: ["client", "user", "file"],
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
    };
  },
  filters: {
    dateFormat(date) {
      return moment.utc(date).format("LLL");
    },
  },
  methods: {
    previousPage() {
      console.log("previous pdf page");

      if (this.currentPage > 1) {
        this.currentPage--;
        this.$parent.socket.emit("fileSharePreviousPage");
        console.log("current page", this.currentPage);
        console.log("page count", this.pageCount);
      }
    },

    nextPage() {
      console.log("next pdf page");

      if (this.currentPage >= 1) {
        if (this.currentPage < this.pageCount) {
          this.currentPage++;
          this.$parent.socket.emit("fileShareNextPage");
          console.log("current page", this.currentPage);
          console.log("page count", this.pageCount);
        }
      }
    },
    close() {
      this.$emit("closed");
    },
  },
  components: {
    Pdf,
  },
};
</script>

<style>
</style>

<template>
  <v-select :options="packages"
            label="name"
            :clearable="false"
            placeholder="Select a package..."
            v-model="selected">
    <template v-slot:option="option">
      {{ option.name }}
    </template>
    <template v-if='loading' #selected-option="{ profile_pic_url, name }">
      <i class="far fa-spin fa-spinner me-1" />
      Loading...
    </template>
    <template v-else #selected-option="{ profile_pic_url, name }">
      {{ name }}
    </template>
    <template #no-options="{ search, searching }">
      <span v-if="loading">Loading...</span>
      <span v-else>
        No packages available.
      </span>
    </template>
  </v-select>
</template>

<script>

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  components: { vSelect },
  props: ["value"],
  data() {
    return {
      loading: true,
      packages: [],
      selected: this.value,
    };
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    selected: {
      handler: function(val) {
        this.$emit("input", this.selected);
      },
      deep: true
    },
  },
  methods: {
    isSelected(el) {
      if (this.selectedElements && this.selectedElements.length) {
        return this.selectedElements.lastIndexOf(el.id) > -1;
      }
      return this.selected && this.selected.id === el.id
    },
    fetchPackages() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/packages/api").then(({ data }) => {
        this.packages = data;
        this.loading = false;
      });
    }
  },
  mounted() {
    this.fetchPackages();
  },
};
</script>

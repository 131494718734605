<template>
  <div>
    <div v-if="consultationForm.package">
      <div v-for="(s, index) in consultationForm.package_schedule">
        <div class="card bg-light mb-3">
          <div class="card-body" :class="consultationForm.package_schedule.length > 1 ? 'pb-2' : ''">
            <h5 class="mb-3">{{s.name}}</h5>
            <select class="form-control mb-3"
                    v-model="s.selected_practitioner_offering"
                    :required="s.confirmed_with_client">
              <option :value="undefined" disabled>Select a practitioner...</option>
              <option v-for="ps in s.practitioner_services" :value="ps">{{ps.user.name}}</option>
            </select>

            <div class="row mb-3">
              <div class="col-6">
                <utc-flat-pickr v-model="s.date" class="me-1 w-100"
                                :req="s.confirmed_with_client"></utc-flat-pickr>
              </div>
              <div class="col-6 d-flex">
                <delivery-method v-model="s.method"
                                 class="ms-auto" :required="s.confirmed_with_client"/>
              </div>
            </div>
            <div class="row" v-if="s.method==='video' || s.method==='other'">
              <div class="col">
                <video-link v-model="s.external_link" />
              </div>
            </div>
            <div class="row mb-3" v-if="s.blockingEvents && s.blockingEvents.length > 0">
              <div class="col">
                <div class="alert alert-danger mb-0">
                  <p>
                    <i class="far fa-info-circle me-1"></i>
                    Your calendar has events which overlap with this slot:
                  </p>
                  <ul class="ms-5">
                    <li v-for="e in s.blockingEvents">
                      {{e.title}}
                      <span v-if="e.client"> with {{e.client.name}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <toggle-button v-model="s.confirmed_with_client"
                               :sync="true"
                               class="my-auto"
                               :color="'#03006b'" />
                <label class="custom-control-label ms-1">
                  Confirmed with Client
                </label>
              </div>
              <div class="col text-end" v-if="consultationForm.package_schedule.length > 1">
                <label class="text-light">.</label>
                <button class="btn btn-light pt-0" @click.prevent="removeService(s)">
                  <i class="far fa-times-circle text-danger"></i>
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtcFlatPickr from "../../../globals/UtcFlatPickr";
import DeliveryMethod from "./DeliveryMethod";
import VideoLink from "./VideoLink";

export default {
  props: ["value"],
  data() {
    return {
      consultationForm: this.value,
      blockingEvents: [],
    };
  },
  methods: {
    removeService(service) {
      this.consultationForm.package_schedule = this.consultationForm.package_schedule.filter(function (filterRow) {
        return filterRow !== service;
      })
    },
    checkDateAvailability(practitioner, date, duration) {
      return this.$axios.post(process.env.VUE_APP_API_URL + "/events/check-availability",
          {
            user_id: practitioner.id,
            start: date,
            duration: duration,
          }
      );
    },
  },
  computed: {
  },
  mounted() {
    let i = 0;
    this.consultationForm.package.services.forEach(item => {
      item.confirmed_with_client = !(i > 0);
      this.consultationForm.package_schedule.push(Object.assign({}, item));
      i++;
    });
  },
  filters: {
  },
  components: {
    DeliveryMethod,
    UtcFlatPickr,
    VideoLink
  },
  watch: {
    consultationForm: {
      deep: true,
      handler(val) {
        if (this.consultationForm.package_schedule) {
          this.consultationForm.package_schedule.forEach(item => {
            if (item.date){
              this.checkDateAvailability(item.practitioner_id, item.date, item.duration).then(function(result) {
                item.blockingEvents = result.data;
              });
            }
          });
        }
        this.$emit("input", this.consultationForm);
      }
    },
  }
};
</script>

<style>
</style>
